import { Col, Descriptions, Empty, List, Row } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../context/auth"
import api from "../../services/api"
import { Translate } from "../../translate"
import { messageError } from "../../util/messages"

const SearchResult = ({ searchValue, setSearchValue }) => {

    const [cameras, setCameras] = useState([])
    const [devices, setDevices] = useState([])
    const [rfids, setRfids] = useState([])
    const [locations, setLocations] = useState([])
    const [surveys, setSurveys] = useState([])
    const [maintenances, setMaintenances] = useState([])
    const [categories, setCategories] = useState([])
    const [events, setEvents] = useState([])
    const { hasAccess, setSearchItemId } = useContext(AuthContext)

    function handleClick(id) {
        setSearchItemId(id)
        setSearchValue(undefined)
    }

    const columns = {
        xxl: 4,
        xl: 3,
        lg: 3,
        md: 3,
        sm: 2,
        xs: 1,
    }

    const fecthCamera = () => {
        api.get("/control/camera/", {
            params: {
                page: 0,
                rowPerPage: 5,
                name: searchValue,
                mac: searchValue,
                serialNumber: searchValue,
                model: searchValue,
                regex: true
            }
        })
            .then((response) => { setCameras(response.data.content) })
            .catch((error) => { messageError(error) })
    }

    const fecthRfid = () => {
        api.get("/maintenance/pin/", {
            params: {
                page: 0,
                rowPerPage: 5,
                name: searchValue,
                code: searchValue,
                regex: true
            }
        })
            .then((response) => { setRfids(response.data.content) })
            .catch((error) => { messageError(error) })
    }



    // const fecthSurvey = () => {
    //     api.get("/survey/survey/", {
    //         params: {
    //             page: 0,
    //             rowPerPage: 5,
    //             grades: searchValue,
    //             categories: searchValue,
    //             locations: searchValue,
    //             regex: true
    //         }
    //     })
    //         .then((response) => { setSurveys(response.data.content) })
    //         .catch((error) => { messageError(error) })
    // }

    const fecthDevice = () => {
        api.get("/control/device/", {
            params: {
                page: 0,
                rowPerPage: 5,
                brand: searchValue,
                deviceId: searchValue,
                regex: true
            }
        })
            .then((response) => { setDevices(response.data.content) })
            .catch((error) => { messageError(error) })
    }

    const fecthMaintenance = () => {
        api.get("/maintenance/maintenance/", {
            params: {
                page: 0,
                rowPerPage: 5,
                rfids: searchValue,
                categories: searchValue,
                locations: searchValue,
                types: searchValue,
                regex: true
            }
        })
            .then((response) => { setMaintenances(response.data.content) })
            .catch((error) => { messageError(error) })

    }

    const fecthCategory = () => {
        api.get("/maintenance/category/", {
            params: {
                page: 0,
                rowPerPage: 5,
                audiences: searchValue,
                name: searchValue,
                regex: true
            }
        })
            .then((response) => { setCategories(response.data.content) })
            .catch((error) => { messageError(error) })
    }


    // const fecthEvents = () => {
    //     api.get("/control/event/", {
    //         params: {
    //             page: 0,
    //             rowPerPage: 5,
    //             types: searchValue,
    //             camera: searchValue,
    //             locations: searchValue,
    //             regex: true
    //         }
    //     })
    //         .then((response) => { setEvents(response.data.content) })
    //         .catch((error) => { messageError(error) })
  //  }

    const fetchLocation = () => {
        api.get("/maintenance/location/", {
            params: {
                page: 0,
                rowPerPage: 5,
                fathers: searchValue,
                types: searchValue,
                audiences: searchValue,
                name: searchValue,
                regex: true
            }
        })
            .then((response) => { setLocations(response.data.content) })
            .catch((error) => { messageError(error) })
    }

    useEffect(() => {
        if (hasAccess("bathroom_control")) {
            fecthCamera()
            fecthDevice()
           // fecthEvents()
        }
        if (hasAccess("bathroom_maintenance")) {
            fecthRfid()
            fecthMaintenance()
            fetchLocation()
            fecthCategory()
        }
        if (hasAccess("bathroom_survey")) {
            //fecthSurvey()
        }
    }, [searchValue])


    return (
        <Row gutter={[16, 16]}>
            {cameras.length === 0 && devices.length === 0 && rfids.length === 0 &&
                locations.length === 0 && surveys.length === 0 && maintenances.length === 0 && categories.length === 0 && events.length === 0 ?
                <Col className="card_default" xs={24}>
                    <Empty />
                </Col> :
                <>    {cameras.length > 0 &&
                    <Col xs={24}>
                        <List className="card_default"
                            size="small"
                            header={<span className="text-header primary-text-color">{Translate.t("menu.camera")}</span>}
                            footer={null}
                            dataSource={cameras}
                            renderItem={(item) => <List.Item   >
                                <Descriptions column={columns} >
                                    <Descriptions.Item span={1} label={Translate.t("common.name.title")}>{item.name}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.serialNumber.title")}>{item.serialNumber}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.location.title")}><span>{item.location ? (item.location.father ? item.location.father.name + " - " : "") + item.location.name : ""}</span></Descriptions.Item>
                                    <Descriptions.Item span={1} ><Link to="/camera" onClick={() => handleClick(item.id)}>{Translate.t("action.go", { name: item.id })}</Link></Descriptions.Item>
                                </Descriptions>
                            </List.Item>}
                        />
                    </Col>}

                    {/* {surveys.length > 0 && <Col xs={24}>
                        <List className="card_default"
                            size="small"
                            header={<span className="text-header primary-text-color">{Translate.t("menu.survey")}</span>}
                            footer={null}
                            dataSource={surveys}
                            renderItem={(item) => <List.Item   >
                                <Descriptions column={columns} >
                                    <Descriptions.Item span={1} label={Translate.t("survey.grades.title")}>{Translate.t(`survey.grades.${item.grade}`)}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.category.title")}>{item.categories ? item.categories.map(i => i.name).toString() : ""}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.location.title")}><span>{item.location ? (item.location.father ? item.location.father.name + " - " : "") + item.location.name : ""}</span></Descriptions.Item>
                                    <Descriptions.Item span={1} ><Link to="/survey" onClick={() => handleClick(item.id)}>{Translate.t("action.go", { name: item.id })}</Link></Descriptions.Item>
                                </Descriptions>
                            </List.Item>}
                        />
                    </Col>} */}

                    {locations.length > 0 && <Col xs={24}>
                        <List className="card_default"
                            size="small"
                            header={<span className="text-header primary-text-color">{Translate.t("menu.locations")}</span>}
                            footer={null}
                            dataSource={locations}
                            renderItem={(item) => <List.Item   >
                                <Descriptions column={columns} >
                                    <Descriptions.Item span={1} label={Translate.t("common.name.title")}>{item.name}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.father.title")}>{item.father ? item.father.name : ""}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.type.title")}>{Translate.t(`location.${item.type}`)}</Descriptions.Item>
                                    <Descriptions.Item span={1} ><Link to="/locations" onClick={() => handleClick(item.id)}>{Translate.t("action.go", { name: item.id })}</Link></Descriptions.Item>
                                </Descriptions>
                            </List.Item>}
                        />
                    </Col>}

                    {rfids.length > 0 && <Col xs={24}>
                        <List className="card_default"
                            size="small"
                            header={<span className="text-header primary-text-color">{Translate.t("menu.rfid")}</span>}
                            footer={null}
                            dataSource={rfids}
                            renderItem={(item) => <List.Item   >
                                <Descriptions column={columns} >
                                    <Descriptions.Item span={1} label={Translate.t("common.name.title")}>{item.name}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.code.title")}>{item.code}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("location.titles")}><span>{item.locations ? item.locations.map(i => i.name).toString() : ""}</span></Descriptions.Item>
                                    <Descriptions.Item span={1} ><Link to="/rfid" onClick={() => handleClick(item.id)}>{Translate.t("action.go", { name: item.id })}</Link></Descriptions.Item>
                                </Descriptions>
                            </List.Item>} /></Col>}

                    {devices.length > 0 && <Col xs={24}>
                        <List className="card_default"
                            size="small"
                            header={<span className="text-header primary-text-color">{Translate.t("menu.device")}</span>}
                            footer={null}
                            dataSource={devices}
                            renderItem={(item) => <List.Item   >
                                <Descriptions column={columns} >
                                    <Descriptions.Item span={1} label={Translate.t("device.deviceId.title")}>{item.deviceId}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("device.brand.title")}>{item.brand}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.location.title")}><span>{item.location ? (item.location.father ? item.location.father.name + " - " : "") + item.location.name : ""}</span></Descriptions.Item>
                                    <Descriptions.Item span={1} ><Link to="/device" onClick={() => handleClick(item.id)}>{Translate.t("action.go", { name: item.id })}</Link></Descriptions.Item>
                                </Descriptions>
                            </List.Item>}
                        />
                    </Col>}

                    {maintenances.length > 0 && <Col xs={24}>
                        <List className="card_default"
                            size="small"
                            header={<span className="text-header primary-text-color">{Translate.t("menu.maintenance")}</span>}
                            footer={null}
                            dataSource={maintenances}
                            renderItem={(item) => <List.Item   >
                                <Descriptions column={columns} >
                                    <Descriptions.Item span={1} label={Translate.t("common.location.title")}>{item.location ? item.location.name : ""}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.type.title")}>{item.type ? Translate.t(`maintenance.${item.type}`) : ""}</Descriptions.Item>
                                    {/*                             <Descriptions.Item span={1} label={Translate.t("common.rfid.title")}>{item.pin ? item.pin.name : ""}</Descriptions.Item> */}
                                    <Descriptions.Item span={1} label={Translate.t("common.category.title")}>{(item.categories || []).map(i => i.name).toString()}</Descriptions.Item>
                                    <Descriptions.Item span={1} ><Link to="/maintenance" onClick={() => handleClick(item.id)}>{Translate.t("action.go", { name: item.id })}</Link></Descriptions.Item>
                                </Descriptions>
                            </List.Item>}
                        />
                    </Col>}


                    {categories.length > 0 && <Col xs={24}>
                        <List className="card_default"
                            size="small"
                            header={<span className="text-header primary-text-color">{Translate.t("menu.category")}</span>}
                            footer={null}
                            dataSource={categories}
                            renderItem={(item) => <List.Item   >
                                <Descriptions column={columns} >
                                    <Descriptions.Item span={1} label={Translate.t("common.name.title")}>{item.name}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("location.audience.title")}>{(item.audiences || []).map(i => (Translate.t(`location.${i}`))).toString()}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("category.notify.title")}>{item.notify}</Descriptions.Item>
                                    <Descriptions.Item span={1} ><Link to="/maintenance/category" onClick={() => handleClick(item.id)}>{Translate.t("action.go", { name: item.id })}</Link></Descriptions.Item>
                                </Descriptions>
                            </List.Item>}
                        />
                    </Col>}

                    {/* {events.length > 0 && <Col xs={24}>
                        <List className="card_default"
                            size="small"
                            header={<span className="text-header primary-text-color">{Translate.t("menu.statistics")}</span>}
                            footer={null}
                            dataSource={events}
                            renderItem={(item) => <List.Item   >
                                <Descriptions column={columns} >
                                    <Descriptions.Item span={1} label={Translate.t("common.action")}>{Translate.t(`event.${item.type}`)}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("camera.title")}>{item.camera.name}</Descriptions.Item>
                                    <Descriptions.Item span={1} label={Translate.t("common.location.title")}><span>{item.camera.location ? (item.camera.location.father ? item.camera.location.father.name + " - " : "") + item.camera.location.name : ""}</span></Descriptions.Item>
                                    <Descriptions.Item span={1} ><Link to="/statistics" onClick={() => handleClick(item.id)}>{Translate.t("action.go", { name: item.id })}</Link></Descriptions.Item>
                                </Descriptions>
                            </List.Item>}
                        />
                    </Col>} */}
                    </>}
        </Row>
    )
}

export default SearchResult