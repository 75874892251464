import { DeleteOutlined, DownOutlined, EditOutlined, ExclamationCircleFilled } from "@ant-design/icons"
import { Button, Checkbox, Col, Modal, Popover, Row, Table } from "antd"
import React, { useEffect, useRef, useState } from "react"
import api from "../../services/api"
import { Translate } from "../../translate"
import { getValueStorage, setValueStorage } from "../../util/localStorange"
import { messageError, messageWarn, notification } from "../../util/messages"
import { datetime } from "../../util/time"
import Register from "./Register"

const List = ({ data, pagination, loading, search, locations, item, setItem, open, setOpen, types }) => {

    const [columnsVisives, setColumnsVisives] = useState([])
    const [contextMenuPosition, setContextMenuPosition] = useState(null)
    const [contextMenuVisible, setContextMenuVisible] = useState(false)
    const divMenuContext = useRef(null)

    const handleContextMenu = (event, record) => {
        const container = document.getElementById("container-table")
        const containerLeft = container.offsetLeft
        const containerTop = container.offsetTop
        event.preventDefault()
        setItem(record)
        setContextMenuPosition({ x: event.clientX - containerLeft, y: event.clientY - containerTop })
        setContextMenuVisible(true)
    }

    const handleOnClick = (event, record) => {
        event.preventDefault()
        if (!contextMenuVisible) {
            handleEdit(record)
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        search({
            page: page.current - 1,
            rowPerPage: page.pageSize
        })
    }

    const handleRemove = async () => {
        try {
            if (item && item.id) {
                let { data } = await api.delete(`/maintenance/pin/${item.id}`)
                if (data > 0) {
                    notification('success', 'remove', Translate.t("rfid.title"))
                    handleTableChange(pagination)
                    setItem(undefined)
                }
            } else {
                messageWarn(Translate.t("common.selectWarn"))
            }
        }
        catch (error) {
            messageError(`Status: ${error.response.data.status}, Error: ${error.response.data.error}, Message: ${error.response.data.message}`)
        }
    }

    const handleEdit = async (_item) => {
        try {
            if (_item && _item.id) {
                let { data } = await api.get(`/maintenance/pin/${_item.id}`)
                setItem({ ...data, locations: data.locations.map(i => i.id) })
                setOpen(true)
            } else {
                messageWarn(Translate.t("common.selectWarn"))
            }
        }
        catch (error) {
            messageError(`Status: ${error.response.data.status}, Error: ${error.response.data.error}, Message: ${error.response.data.message}`)
        }
    }

    const columns = [
        {
            index: 0,
            title: Translate.t("common.code.title"),
            dataIndex: 'code',
            key: 'code',
            width: 200
        },
        {
            index: 1,
            title: Translate.t("common.name.title"),
            dataIndex: 'name',
            key: 'name',
            width: 250
        },
        {
            index: 2,
            title: Translate.t("common.type.title"),
            dataIndex: 'type',
            key: 'type',
            width: 250,
            render: (text, record) => text ? Translate.t(`maintenance.${text}`) : ""
        },
        {
            index: 3,
            title: Translate.t("location.titles"),
            dataIndex: 'locations',
            key: 'locations',
            width: 300,
            render: (text, record) => record.locations ? record.locations.map(i => i.name).toString() : ""
        },
        {
            index: 4,
            title: Translate.t("common.enabled"),
            dataIndex: 'enabled',
            key: 'enabled',
            width: 200,
            render: (text, record) => text ? Translate.t("action.yes") : Translate.t("action.no")
        },
        {
            index: 5,
            title: Translate.t("common.creationDate"),
            dataIndex: 'creationDate',
            key: 'creationDate',
            width: 200,
            render: (text, record) => text ? datetime(text) : ""
        },
        {
            index: 6,
            title: Translate.t("common.id.title"),
            dataIndex: 'id',
            key: 'id',
            width: 250
        }
    ]

    const items = [
        {
            key: '1',
            item: (
                <Button key="edit" disabled={!item} title={Translate.t("action.edit", { name: "" })} icon={<EditOutlined />} onClick={() => {
                    handleEdit(item)
                    setContextMenuVisible(false)
                }}>
                    {Translate.t("action.edit", { name: "" })}
                </Button>
            ),
        },
        {
            key: '2',
            item: (
                <Button disabled={!item} type="link" icon={<DeleteOutlined />} title={Translate.t("action.delete")}
                    key="remove"
                    onClick={() => {
                        setContextMenuVisible(false)
                        Modal.confirm({
                            title: Translate.t("message.confirm"),
                            content: Translate.t("message.confirmContentDelete", { name: Translate.t("rfid.title") }),
                            icon: <ExclamationCircleFilled />,
                            okText: Translate.t("action.yes"),
                            cancelText: Translate.t("action.no"),
                            onOk() {
                                return new Promise(async (resolve, reject) => resolve(await handleRemove())).catch(() => alert(Translate.t("error.operation")))
                            },
                            onCancel() { },
                        })
                    }}
                >
                    {Translate.t("action.delete")}
                </Button>
            ),
        },
    ]

    const handleHiddenColumns = (collIndex) => {
        if (columnsVisives.length > 0)
            return columnsVisives.find((i, index) => index === collIndex && i.visible)
        return true
    }

    const onChangeHiddenColumns = (index, checked) => {
        let _columns = (columnsVisives || []).map((i, _index) => ({ ...i, visible: _index === index ? checked : i.visible }))
        setValueStorage("columnsCamera", JSON.stringify(_columns))
        setColumnsVisives(_columns)
    }


    useEffect(() => {
        let value = getValueStorage("columnsRfids")
        if (value)
            setColumnsVisives(JSON.parse(value))
        else {
            let _columns = [
                { label: Translate.t("common.code.title"), key: "1", visible: true },
                { label: Translate.t("common.name.title"), key: "2", visible: true },
                { label: Translate.t("common.type.title"), key: "3", visible: true },
                { label: Translate.t("location.titles"), key: "4", visible: true },
                { label: Translate.t("common.enabled"), key: "5", visible: true },
                { label: Translate.t("common.creationDate"), key: "6", visible: true },
                { label: Translate.t("common.id.title"), key: "7", visible: true },
            ]
            setValueStorage("columnsRfids", JSON.stringify(_columns))
            setColumnsVisives(_columns)
        }
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (divMenuContext.current && !divMenuContext.current.contains(event.target)) {
                setContextMenuVisible(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [divMenuContext])

    return (
        <>
            <Row id="container-table" style={{ marginTop: "20px" }}>
                <Col xs={24} className="card_default padding-list" >
                    <Row className="space-botton padding" >
                        <Col xs={12}>
                            <span className="text-header primary-text-color">{Translate.t("title.search_result")}</span>
                        </Col>
                        <Col xs={12} className="alng-rigth">
                            {columnsVisives.length > 0 && <Popover content={(columnsVisives || []).map((i, index) => <p value={i.label}>
                                <Checkbox checked={i.visible}
                                    onClick={(e) => {
                                        onChangeHiddenColumns(index, e.target.checked)
                                        e.stopPropagation()
                                    }}
                                >   <span className="secondary-text-color">{i.label}</span>
                                </Checkbox>
                            </p>)} placement="bottom" title={null} trigger="hover">
                                <span className="secondary-text-color"> {Translate.t("common.selectInfo")}</span>
                                <DownOutlined />
                            </Popover>}
                        </Col>
                    </Row >
                    <Table
                        scroll={{ y: 9999, x: 400 }}
                        dataSource={data}
                        columns={columns.filter((column) => handleHiddenColumns(column.index))}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        rowKey={(row) => row.id}
                        onRow={(record) => {
                            return {
                                onClick: (event) => handleOnClick(event, record),
                                onContextMenu: (event) => handleContextMenu(event, record)
                            }
                        }}
                    />
                    {contextMenuVisible && (
                        <ul ref={divMenuContext} className="popup" style={{ left: `${contextMenuPosition.x}px`, top: `${contextMenuPosition.y}px` }}>
                            {(items || []).map(i => <li className="secondary-text-color" key={i.key}>{i.item}</li>)}
                        </ul>
                    )}
                </Col>

            </Row>
            {open && <Register
                initialValues={item}
                open={open}
                setOpen={setOpen}
                search={search}
                locations={locations}
                types={types}
            />}
        </>
    )
}

export default List