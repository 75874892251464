import { Button, Col, Descriptions, Modal, Row, Timeline } from "antd"
import React from "react"
import { Translate } from "../../translate"
import { datetime } from "../../util/time"

const View = ({ item = {}, open, setOpen }) => {

    return (
        <Modal
            title={<span>{Translate.t("maintenance.title")}</span>}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button type="primary" key="close" onClick={() => setOpen(false)}>
                    {Translate.t("action.close")}
                </Button>,
            ]}>
            <Row className="default-padding">
                <Col xs={24}  >
                    <Descriptions column={1} >
                        <Descriptions.Item label={Translate.t("common.location.title")}>{item.location ? item.location.name : ""}</Descriptions.Item>
                        <Descriptions.Item label={Translate.t("common.type.title")}>{item.type ? Translate.t(`maintenance.${item.type}`) : ""}</Descriptions.Item>
                        <Descriptions.Item label={Translate.t("common.status.title")}>{item.status ? Translate.t(`maintenance.status.${item.status}`) : ""}</Descriptions.Item>
                        <Descriptions.Item label={Translate.t("common.rfid.title")}>{item.pin ? item.pin.name : ""}</Descriptions.Item>
                        <Descriptions.Item label={Translate.t("maintenance.start.title")}>{item.start ? datetime(item.start) : ""}</Descriptions.Item>
                        <Descriptions.Item label={Translate.t("maintenance.end.title")}>{item.end ? datetime(item.end) : ""}</Descriptions.Item>
                        <Descriptions.Item label={Translate.t("common.creationDate")}>{item.creationDate ? datetime(item.creationDate) : ""}</Descriptions.Item>
                        <Descriptions.Item label={Translate.t("common.id.title")}>{item.id}</Descriptions.Item>
                        <Descriptions.Item >
                            <Descriptions column={2} title={Translate.t("common.category.title")} >
                                {(item.categories || []).map(i => <>
                                    <Descriptions.Item label={Translate.t("common.name.title")}>{i.name}</Descriptions.Item>
                                    <Descriptions.Item label={Translate.t("common.category.amount.title")}>{i.amount}</Descriptions.Item>
                                </>)}
                            </Descriptions >
                        </Descriptions.Item>
                        <Descriptions.Item column={1}>
                            <Descriptions column={1} title={Translate.t("maintenance.histories.title")} >
                                <Timeline mode="left">
                                    {(item.histories || []).map(i => (
                                        <Row>
                                            <Timeline.Item label={datetime(i.timestamp)}>{`${Translate.t(`maintenance.status.${i.status}`)} - ${i.rfid}`}</Timeline.Item>
                                        </Row>
                                    ))}
                                </Timeline>
                            </Descriptions >
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </Modal>
    )
}
export default View