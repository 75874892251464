import { Button, Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import video from "../../assets/icons/actions/video.svg";
import Filter from "../../components/camera/Filter";
import List from "../../components/camera/List";
import { AuthContext } from "../../context/auth";
import api from "../../services/api";
import { Translate } from "../../translate";
import { messageError } from "../../util/messages";

const Camera = () => {
  const { environment, searchItemId } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [params, setParams] = useState({
    page: 0,
    rowPerPage: 5,
    enabled: true,
    order: "desc",
  });
  const [locations, setLocations] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState();

  const handleNew = () => {
    setOpen(true);
    setItem({ enabled: true, deleted: false });
  };

  const fetchLocations = () => {
    api
      .get("/maintenance/location/tree/", { params: { disabled: true } })
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => messageError(error));
  };

  const fetchHosts = () => {
    api
      .get("/control/hosthttp/", {
        params: {
          deleted: false,
          page: 0,
          rowPerPage: 100,
          order: "desc",
        },
      })
      .then((response) => {
        setHosts(response.data.content);
      })
      .catch((error) => messageError(error));
  };

  const search = (values) => {
    setLoading(true);
    let _params = {
      ...params,
      ...(values || {}),
    };
    setParams(_params);
    api
      .get("/control/camera/", { params: _params })
      .then((response) => {
        setData(response.data.content);
        setPagination({
          current: response.data.number + 1,
          pageSize: response.data.size,
          total: response.data.totalElements,
        });
      })
      .catch((error) => {
        messageError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLocations();
    fetchHosts();
  }, [environment]);

  useEffect(() => {
    if (searchItemId)
      search({
        id: searchItemId,
        page: 0,
        rowPerPage: 5,
        enabled: true,
        order: "desc",
      });
  }, [searchItemId]);

  return (
    <>
      <Row>
        <Col xs={24} className="space-botton">
          <span className="text-title primary-text-color">
            {Translate.t("menu.camera")}
          </span>
        </Col>
        <Col xs={18} sm={16} md={14} lg={12} className="space-botton">
          <span className="text-sub secondary-text-color">
            {Translate.t("title.info.camera")}
          </span>
        </Col>
        <Col xs={6} sm={8} md={10} lg={12} className="alng-rigth space-botton">
          <Button
            type={"primary"}
            icon={<img className="btn-img-icon" src={video} alt="video" />}
            onClick={handleNew}
            title={Translate.t("action.new", {
              name: Translate.t("camera.title"),
            })}
          >
            <span className="no-mobile">
              {" "}
              {Translate.t("action.new", { name: Translate.t("camera.title") })}
            </span>
          </Button>
        </Col>
      </Row>
      <Filter
        search={search}
        hosts={hosts}
        loading={loading}
        locations={locations}
      />
      {
        <List
          search={search}
          pagination={pagination}
          data={data}
          loading={loading}
          locations={locations}
          open={open}
          setOpen={setOpen}
          item={item}
          setItem={setItem}
          hosts={hosts}
        />
      }
    </>
  );
};

export default Camera;
