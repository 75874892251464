import { ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row } from "antd";

import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import bad from "../../assets/icons/actions/bad.svg";
import empty from "../../assets/icons/actions/empty.svg";
import excellent from "../../assets/icons/actions/excellent.svg";
import good from "../../assets/icons/actions/good.svg";
import load from "../../assets/icons/actions/load.gif";
import regular from "../../assets/icons/actions/regular.svg";
import { AuthContext } from "../../context/auth";
import api from "../../services/api";
import { Translate } from "../../translate";


const Notification = ({height}) => {

    const { notification, environment } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ page: 0, last: true })

    const handleCard = (value, index) => {
        return (
            <Row className="row-img" style={{ marginBottom: "2em"}}>
                <Col xs={4}>
                    < >{loadImg(value.img ? value.img : undefined)}</>
                </Col>
                <Col xs={20} >
                    <div className="primary-text-color notification-title">{value.title}</div>
                    <div className="secondary-text-color" style={{ marginTop: "2px" }} > {handleDateDiff(value.time)} </div>
                </Col>
            </Row>
        )
    }

    const handleImageClass = async (imgId) => {
        let collection = document.getElementsByClassName(`img-${imgId}`)
        let request = true
        let loaded = undefined
        for (let i = 0; i < collection.length; i++) {
            if (collection[i].dataset.load === 'loaded') {
                loaded = collection[i].src
                request = false
            }
            if (collection[i].dataset.load === 'load' && loaded)
                collection[i].src = loaded
        }
        if (request) {
            let { data } = await api.get(`/maintenance/files/render/${imgId}`)
            if (data.content) {
                for (let i = 0; i < collection.length; i++)
                    if (collection[i].dataset.load === 'load') {
                        collection[i].src = `data:${data.contentType};base64,${data.content}`
                        collection[i].dataset.load = "loaded"
                        collection[i].style = "filter: brightness(0.9) invert(.7) sepia(.5) hue-rotate(100deg) saturate(200%)"
                    }
            }
        }
    }

    const handleBase64 = (collection) => {
        let base64 = undefined
        if (collection)
            for (let i = 0; i < collection.length; i++) {
                if (collection[i].dataset.load === 'loaded' && collection[i].src)
                    return collection[i].src
            }
        return base64
    }

    const loadImg = (img) => {
        switch (img) {
            case "bad":
                return <img className="avg-im" height={20} src={bad} alt="" />
            case "regular":
                return <img className="avg-im" height={20} src={regular} alt="" />
            case "good":
                return <img className="avg-im" height={20} src={good} alt="" />
            case 'excellent':
                return <img className="avg-im" height={20} src={excellent} alt="" />
            case undefined:
                return <img className="avg-im" height={20} src={empty} alt="" />
            default:

                let base64 = handleBase64(document.getElementsByClassName(`img-${img}`))
                if (!base64) {
                    handleImageClass(img)
                    return <img data-load="load" className={`avg-im img-${img}`} height={20} src={load} alt="" />
                } else {
                    return <img data-load="loaded" style={{filter: "brightness(0.9) invert(.7) sepia(.5) hue-rotate(100deg) saturate(200%)"}} className={`avg-im img-${img}`} height={20} src={base64} alt="" />
                }
        }
    }

    const loadMoreData = (parans) => {
        setLoading(true)
        api.get("/survey/survey/page/notification",
            {
                params: parans
            }).then((response) => {
                if (response.data.empty === false) {
                    if (response.data.number === 0)
                        setData(response.data.content)
                    else
                        setData([...data, ...response.data.content])
                }
                setPagination({ page: response.data.number, last: response.data.last })
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    String.prototype.replaceAt = function(index, replacement) {
        return this.substring(0, index) + replacement + this.substring(index + replacement.length);
    }

    const handleDateDiff = (creationDate) => {
        let now = dayjs()
        let _result = "";
        let _day = now.diff(dayjs(creationDate), 'day')
        if(_day > 0){
            _result += `${_day} dia${_day > 1 ? "s" : ""}, `
            now = now.subtract(_day, 'day')
        }

        let _hour = now.diff(dayjs(creationDate), 'hour')
        if(_hour > 0){
            _result += `${_hour} hora${_hour > 1 ? "s" : ""}, `
            now = now.subtract(_hour, 'hour')
        }

        let _minute = now.diff(dayjs(creationDate), 'minute')
        if(_minute > 0){
            _result += `${_minute} minuto${_minute > 1 ? "s" : ""}, `
            now = now.subtract(_minute, 'minute')
        }

        let _seconds = now.diff(dayjs(creationDate), 'second')
        if(_seconds > 0){
            _result += `${_seconds} segundo${_seconds > 1 ? "s" : ""}, `
            now = now.subtract(_seconds, 'second')
        }
        
        let _lastSeparator = _result.lastIndexOf(',');
        if(_lastSeparator > 0){
            _result = _result.replaceAt(_lastSeparator, " ")
        }
        _lastSeparator = _result.lastIndexOf(',');
        if(_lastSeparator > 0){
            _result = _result.replaceAt(_lastSeparator, " e ")
        }
        return _result
    }

    const onClick = () => {
        if (!pagination.last)
            loadMoreData({ rowPerPage: 15, page: pagination.page + 1 })
    }

    useEffect(() => {
        loadMoreData({ rowPerPage: 15, page: 0 })
    }, [environment])

    useEffect(() => {
        if (!loading) {
            if (data.length === 0) {
                setData(notification)
            } else {
                let newNotification = notification.filter(i => !data.map(i => i.key).includes(i.key))
                if (newNotification.length > 0)
                    setData([...newNotification, ...data])
            }
        }
    }, [notification])

    return (
        <Col className="div-dash-itens">
            <Col gutter={[32, 32]} style={{ overflowY: "auto", height: window.screen.height - height }}>

                {data.length > 0 ? (data || []).map((i, index) => handleCard(i, index)) : <Empty style={{ marginTop: "10%" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                <div style={{ textAlign: "center" }}>
                    <Button title={Translate.t("dashboard.loadmore")} style={{ border: "none" }} disabled={pagination.last} icon={<ReloadOutlined />} loading={loading} onClick={() => onClick()} type="link">{Translate.t("dashboard.loadmore")}</Button>
                </div>
            </Col>
        </Col>
    )

}
export default Notification
