import { message, notification as notfy } from "antd";
import { Translate } from "../translate";

export const messageSuccess = (args) => {
  if (args) message.success(args);
};

export const messageError = (args) => {
  if (args.response) {
    message.error(
      `Status: ${args.response.status}, Error: ${
        args.response.data.error
      }, Message: ${messageFormat(args.response.data)}`
    );
  } else message.error(args.message);
};

function messageFormat(data) {
  if (data.errors && data.errors.length > 0)
    return data.errors.map((i) => i.message).toString();
  return data.message ? data.message : data.error_description;
}

export const messageWarn = (args) => {
  if (args) message.warning(args);
};

export const messageInfo = (args) => {
  if (args) message.info(args);
};

export const notification = (type, action, obj) => {
  notfy[type]({
    message: Translate.t("message.succes"),
    description: Translate.t("message." + action, { name: obj }),
  });
};
