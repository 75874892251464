import { DeleteOutlined, ExclamationCircleFilled, LeftCircleOutlined, MenuOutlined, PlusCircleOutlined, SnippetsOutlined } from "@ant-design/icons"
import { Button, Col, Dropdown, Empty, Modal, Row } from "antd"
import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { Translate } from "../../translate"
import { messageError, messageInfo, notification } from "../../util/messages"
import Register from "./Register"
import SearchTree from "./SearchTree"

const List = ({ locations, fetchLocations, types, audiences, directions }) => {

    const [open, setOpen] = useState(false)
    const [item, setItem] = useState()
    const [itemCopy, setItemCopy] = useState()
    const [selectedKeys, setSelectedKeys] = useState([])
    const [defaultExpandedKeys, setdDefaultExpandedKeys] = useState(false)


    const handleDefaultExpandedKeys = () => {
        const list = []
        handleChildrens(locations, list)
        setdDefaultExpandedKeys(list)
    }

    const handleChildrens = (values = [], list) => {
        values.forEach(obj => {
            list.push(obj.key)
            if (obj.children)
                handleChildrens(obj.children, list)
        })
    }

    const fectLocation = async (value) => {
        try {
            let { data } = await api.get(`/maintenance/location/${value[0]}`)
            return data
        }
        catch (error) {
            messageError(error)
        }
    }


    const handleCopy = async () => {
        try {
            let { data } = await api.post(`/maintenance/location/paste/${item.id}/${itemCopy.id}`)
            notification('success', 'save', Translate.t("location.title"))
            setItem({})
            setItemCopy({})
            setSelectedKeys('')
            fetchLocations()
        }
        catch (error) {
            messageError(error)
        }
    }

    const handleRemove = async () => {
        try {
            let { data } = await api.delete(`/maintenance/location/${item.id}`)
            if (data > 0) {
                notification('success', 'remove', Translate.t("location.title"))
                setItem({})
                setOpen(false)
                setSelectedKeys('')
                fetchLocations()
            }
        }
        catch (error) {
            messageError(error)
        }
    }

    const handleEdit = async (value) => {
        if (value.length > 0) {
            let data = await fectLocation(value)
            setItem({ ...data, father: data.father ? data.father.id : undefined })
            setSelectedKeys(value)
            setOpen(true)
        } else {
            setItem({})
            setSelectedKeys("")
            setOpen(false)
        }

    }

    const handleNew = async () => {
        if (item && item.id && item.type !== 'bathroom')
            setItem({ father: item.id })
        else
            setItem({})
        setSelectedKeys('')
        setOpen(true)
    }

    const disabledCopy = () => {
        if (item && item.id) {
            return false
        }
        return true
    }

    const disabledPaste = () => {
        if (itemCopy && item && item.id) {
            if (item.type === 'bathroom')
                return true
            return false
        }
        return true
    }

    useEffect(() => { handleDefaultExpandedKeys() }, [locations])

    const items = [
        {
            key: '1',
            label: (
                <span onClick={() => handleNew()} key="new" title={Translate.t("action.new", { name: Translate.t("location.title") })}>
                    {Translate.t("action.new", { name: Translate.t("location.title") })}
                </span>
            ),
            icon: <PlusCircleOutlined />,
        },
        {
            key: '2',
            label: (
                <span onClick={() => {
                    setItemCopy(item)
                    messageInfo("Selecion o local de destino para colar")
                }} key="clone" title={Translate.t("action.copy", { name: Translate.t("location.bathroom") })} >
                    {Translate.t("action.copy", { name: Translate.t("location.bathroom") })}
                </span>
            ),
            icon: <SnippetsOutlined />,
            disabled: disabledCopy(),
        },
        {
            key: '3',
            label: (
                <span onClick={() => handleCopy()} key="clone" title={Translate.t("action.paste", { name: Translate.t("location.bathroom") })} >
                    {Translate.t("action.paste", { name: Translate.t("location.bathroom") })}
                </span>
            ),
            icon: <SnippetsOutlined />,
            disabled: disabledPaste(),
        }
        ,
        {
            key: '4',
            label: (
                <span key="remove"
                    onClick={() => {
                        Modal.confirm({
                            title: Translate.t("message.confirm"),
                            content: Translate.t("message.confirmContentDelete", { name: Translate.t("location.title") }),
                            icon: <ExclamationCircleFilled />,
                            okText: Translate.t("action.yes"),
                            cancelText: Translate.t("action.no"),
                            onOk() {
                                return new Promise(async (resolve, reject) => resolve(await handleRemove())).catch(() => alert('Erro ao realizar esta operação'))
                            },
                            onCancel() { },
                        })
                    }}
                    title={Translate.t("action.delete", { name: "" })}
                >
                    {Translate.t("action.delete", { name: "" })}
                </span>
            ),
            disabled: !item || !item.id,
            icon: <DeleteOutlined />
        },
    ]

    return (
        <Col xs={24}>
            <Row gutter={32} style={{ marginTop: "22px"}} >
                <Col xs={24} sm={24} md={8} lg={8} className="space-botton" >
                    <Col xs={24} className="card_default padding-list">
                        <Col xs={24} style={{ padding: "0 20px 10px" }}>
                            <span className="text-header primary-text-color">{Translate.t("title.search")}</span>
                        </Col>
                        <Col xs={24}>
                            <div id="searchTree"> <SearchTree id="searchTree" defaultExpandedKeys={defaultExpandedKeys} locations={locations} onSelectTree={handleEdit} selectedKeys={selectedKeys} /></div>
                        </Col>
                    </Col>
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} className="space-botton">
                    <Col xs={24} className="card_default padding-list" style={{height: "77.5vh"}} >
                        <Row gutter={[16, 16]}  >
                            <Col xs={24} sm={14} md={14} lg={14} style={{ padding: "0 20px 10px" }}>
                                {open ? <span className="text-header primary-text-color">{item.id ? item.name : Translate.t("action.new", { name: Translate.t("location.title") })}</span> : <></>}
                            </Col>
                            <Col xs={24} sm={10} md={10} lg={10} className="alng-rigth ">
                                <Button.Group>
                                    {!item &&
                                        <Button icon={<PlusCircleOutlined />} onClick={() => handleNew()}>
                                            {Translate.t("action.new", { name: Translate.t("location.title") })}
                                        </Button>
                                    }

                                    {item &&
                                        <Button onClick={() => {
                                            setOpen(false)
                                            setItem()
                                            setSelectedKeys('')
                                        }}
                                            icon={<LeftCircleOutlined />}
                                        >
                                            {Translate.t("common.back")}
                                        </Button>
                                    }
                                    <Dropdown
                                        disabled={!item}
                                        menu={{ items }}
                                    >
                                        <Button disabled={!item} className="btn-menu-local" icon={<MenuOutlined />} />
                                    </Dropdown>
                                </Button.Group>
                            </Col>
                        </Row>
                        {open ?
                            <Register
                                initialValues={item}
                                open={open}
                                setSelectedKeys={setSelectedKeys}
                                locations={locations}
                                fetchLocations={fetchLocations}
                                types={types}
                                audiences={audiences}
                                directions={directions}
                            /> :
                            <Empty style={{ marginTop: "10%", marginBottom: "13%" }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={Translate.t("location.empty")} />}

                    </Col>
                </Col>
            </Row>
        </Col>
    )
}

export default List