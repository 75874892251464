import { DeleteOutlined, DownOutlined, EditOutlined, ExclamationCircleFilled, EyeOutlined } from "@ant-design/icons"
import { Button, Checkbox, Col, Modal, Popover, Row, Table } from "antd"
import React, { useEffect, useRef, useState } from "react"
import api from "../../services/api"
import { Translate } from "../../translate"
import { getValueStorage, setValueStorage } from "../../util/localStorange"
import { messageError, messageWarn, notification } from "../../util/messages"
import { datetime, toDateLocal } from "../../util/time"
import Register from "./Register"
import View from "./view"

const List = ({ data, pagination, loading, search, categories, locations, status, rfids, item, setItem, open, setOpen, types }) => {

    const [openView, setOpenView] = useState(false)
    const [loadingReq, setLoadingReq] = useState(false)
    const [columnsVisives, setColumnsVisives] = useState([])
    const [contextMenuPosition, setContextMenuPosition] = useState(null)
    const [contextMenuVisible, setContextMenuVisible] = useState(false)
    const divMenuContext = useRef(null)

    const handleContextMenu = (event, record) => {
        const container = document.getElementById("container-table")
        const containerLeft = container.offsetLeft
        const containerTop = container.offsetTop
        event.preventDefault()
        setItem(record)
        setContextMenuPosition({ x: event.clientX - containerLeft, y: event.clientY - containerTop })
        setContextMenuVisible(true)
    }

    const handleOnClick = (event, record) => {
        event.preventDefault()
        if (!contextMenuVisible) {
            handleEdit(record)
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        search({
            page: page.current - 1,
            rowPerPage: page.pageSize
        })
    }

    const handleRemove = async () => {
        try {
            if (item && item.id) {
                setLoadingReq(true)
                let { data } = await api.delete(`/maintenance/maintenance/${item.id}`)
                if (data > 0) {
                    notification('success', 'remove', Translate.t("maintenance.title"))
                    handleTableChange(pagination)
                    setItem(undefined)
                }
            } else {
                messageWarn(Translate.t("common.selectWarn"))
            }
        }
        catch (error) {
            messageError(error)
        } finally {
            setLoadingReq(false)
        }
    }

    const handleEdit = async (_item) => {
        if (_item && _item.id) {
            let { data } = await api.get(`/maintenance/maintenance/${_item.id}`)
            setItem({ ...data, start: data.start ? toDateLocal(data.start) : "", histories: (data.histories || []).map(i => ({ ...i, timestamp: toDateLocal(i.timestamp) })) })
            setOpen(true)
        } else {
            messageWarn(Translate.t("common.selectWarn"))
        }
    }

    const handleDownload = async (_item) => {
        if (_item && _item.id) {
            let response = await api.get(`/maintenance/maintenance/report/pdf`, {
                responseType: 'blob'
            })
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            let link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'report.pdf')
            document.body.appendChild(link)
            link.click()
        } else {
            messageWarn(Translate.t("common.selectWarn"))
        }
    }



    const handleView = async () => {
        setContextMenuVisible(false)
        try {
            if (item && item.id) {
                setLoadingReq(true)
                let { data } = await api.get(`/maintenance/maintenance/${item.id}`)
                setItem(data)
                setOpenView(true)
            } else {
                messageWarn(Translate.t("common.selectWarn"))
            }
        }
        catch (error) {
            messageError(error)
        } finally {
            setLoadingReq(false)
        }
    }

    const columns = [
        {
            index: 0,
            title: Translate.t("common.location.title"),
            dataIndex: ["location", "name"],
            key: 'location.name',
            width: 300,
            render: (text, record) => <span>{(record.location.father ? record.location.father.name + " - " : "") + record.location.name}</span>
        },
        {
            index: 1,
            title: Translate.t("common.category.title"),
            dataIndex: 'categories',
            key: 'categories',
            width: 800,
            render: (text, record) => {
                return record.categories ? record.categories.map(category => (
                    <span key={category.id} >{category.name}</span>
                )) : ""
            }
        },
        {
            index: 2,
            title: Translate.t("common.status.title"),
            dataIndex: 'status',
            key: 'status',
            width: 300,
            render: (text, record) => text ? Translate.t(`maintenance.status.${text}`) : ""
        },
        {
            index: 3,
            title: Translate.t("common.type.title"),
            dataIndex: 'type',
            key: 'type',
            width: 300,
            render: (text, record) => text ? Translate.t(`maintenance.${text}`) : ""
        },
        {
            index: 4,
            title: Translate.t("common.rfid.title"),
            dataIndex: ["rfid", "name"],
            key: 'pin.name',
            width: 300,
            render: (text, record) => record.rfid ? record.rfid.name : ""
        },
        {
            index: 5,
            title: Translate.t("maintenance.start.title"),
            dataIndex: 'start',
            key: 'start',
            width: 300,
            render: (text, record) => text ? datetime(text) : ''
        },
        {
            index: 6,
            title: Translate.t("maintenance.end.title"),
            dataIndex: 'end',
            key: 'end',
            width: 300,
            render: (text, record) => text ? datetime(text) : ''
        },
        {
            index: 7,
            title: Translate.t("common.enabled"),
            dataIndex: 'enabled',
            key: 'enabled',
            width: 300,
            render: (text, record) => text ? Translate.t("action.yes") : Translate.t("action.no")
        },
        {
            index: 8,
            title: Translate.t("common.creationDate"),
            dataIndex: 'creationDate',
            key: 'creationDate',
            width: 300,
            render: (text, record) => text ? datetime(text) : ''
        },
        {
            index: 9,
            title: Translate.t("common.id.title"),
            dataIndex: 'id',
            key: 'id',
            width: 300
        }

    ]

    const items = [
        {
            key: '1',
            item: (
                <Button key="view" disabled={!item} icon={<EyeOutlined />} onClick={() => { return new Promise(async (resolve, reject) => resolve(await handleView())).catch(() => alert(Translate.t("error.operation"))) }
                } >
                    {Translate.t("action.view", { name: "" })}
                </Button>
            ),
        },
        {
            key: '2',
            item: (
                <Button key="edit" disabled={!item} title={Translate.t("action.edit", { name: "" })} icon={<EditOutlined />} onClick={() => {
                    handleEdit(item)
                    setContextMenuVisible(false)
                }}>
                    {Translate.t("action.edit", { name: "" })}
                </Button>
            ),
        },
/*         {
            key: '3',
            item: (
                <Button key="download" disabled={!item} title={Translate.t("action.download")} icon={<DownloadOutlined />} onClick={() => {
                    handleDownload(item)
                }}>
                    {Translate.t("action.download")}
                </Button>
            ),
        }, */
        {
            key: '4',
            item: (
                <Button key="remove" disabled={!item} title={Translate.t("action.delete")} icon={<DeleteOutlined />}
                    onClick={() => {
                        setContextMenuVisible(false)
                        Modal.confirm({
                            title: Translate.t("message.confirm"),
                            content: Translate.t("message.confirmContentDelete", { name: Translate.t("maintenance.title") }),
                            icon: <ExclamationCircleFilled />,
                            okText: Translate.t("action.yes"),
                            cancelText: Translate.t("action.no"),
                            onOk() {
                                return new Promise(async (resolve, reject) => resolve(await handleRemove())).catch(() => alert(Translate.t("error.operation")))
                            },
                            onCancel() { },
                        })
                    }}
                >
                    {Translate.t("action.delete")}
                </Button>
            ),
        },

    ]

    const handleHiddenColumns = (collIndex) => {
        if (columnsVisives.length > 0)
            return columnsVisives.find((i, index) => index === collIndex && i.visible)
        return true
    }

    const onChangeHiddenColumns = (index, checked) => {
        let _columns = (columnsVisives || []).map((i, _index) => ({ ...i, visible: _index === index ? checked : i.visible }))
        setValueStorage("columnsCamera", JSON.stringify(_columns))
        setColumnsVisives(_columns)
    }


    useEffect(() => {
        let value = getValueStorage("columnsMaintenance")
        if (value)
            setColumnsVisives(JSON.parse(value))
        else {
            let _columns = [
                { label: Translate.t("common.location.title"), key: "1", visible: true },
                { label: Translate.t("common.category.title"), key: "2", visible: true },
                { label: Translate.t("common.status.title"), key: "3", visible: true },
                { label: Translate.t("common.type.title"), key: "4", visible: true },
                { label: Translate.t("common.rfid.title"), key: "5", visible: true },
                { label: Translate.t("maintenance.start.title"), key: "6", visible: true },
                { label: Translate.t("maintenance.end.title"), key: "7", visible: true },
                { label: Translate.t("common.enabled"), key: "8", visible: true },
                { label: Translate.t("common.creationDate"), key: "9", visible: true },
                { label: Translate.t("common.id.title"), key: "10", visible: true },
            ]
            setValueStorage("columnsMaintenance", JSON.stringify(_columns))
            setColumnsVisives(_columns)
        }
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (divMenuContext.current && !divMenuContext.current.contains(event.target)) {
                setContextMenuVisible(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [divMenuContext])


    return (
        <>
            <Row id="container-table" style={{ marginTop: "20px" }}>
                <Col xs={24} className="card_default padding-list" >
                    <Row className="space-botton padding" >
                        <Col xs={12}>
                            <span className="text-header primary-text-color">{Translate.t("title.search_result")}</span>
                        </Col>
                        <Col xs={12} className="alng-rigth">
                            {columnsVisives.length > 0 && <Popover content={(columnsVisives || []).map((i, index) => <p value={i.label}>
                                <Checkbox checked={i.visible}
                                    onClick={(e) => {
                                        onChangeHiddenColumns(index, e.target.checked)
                                        e.stopPropagation()
                                    }}
                                >   <span className="secondary-text-color">{i.label}</span>
                                </Checkbox>
                            </p>)} placement="bottom" title={null} trigger="hover">
                                <span className="secondary-text-color"> {Translate.t("common.selectInfo")}</span>
                                <DownOutlined />
                            </Popover>}
                        </Col>
                    </Row >
                    <Table
                        scroll={{ y: 99999, x: 400 }}
                        dataSource={data}
                        columns={columns.filter((column) => handleHiddenColumns(column.index))}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        rowKey={(row) => row.id}
                        onRow={(record) => {
                            return {
                                onClick: (event) => handleOnClick(event, record),
                                onContextMenu: (event) => handleContextMenu(event, record)
                            }
                        }}
                    />
                    {contextMenuVisible && (
                        <ul ref={divMenuContext} className="popup" style={{ left: `${contextMenuPosition.x}px`, top: `${contextMenuPosition.y}px` }}>
                            {(items || []).map(i => <li className="secondary-text-color" key={i.key}>{i.item}</li>)}
                        </ul>
                    )}
                </Col>

            </Row>
            {open &&
                <Register
                    search={search}
                    initialValues={item}
                    open={open}
                    setOpen={setOpen}
                    categories={categories}
                    locations={locations}
                    status={status}
                    rfids={rfids}
                    types={types}
                />}

            {openView &&
                <View
                    open={openView}
                    setOpen={setOpenView}
                    item={item} />}
        </>
    )
}

export default List