import axios from "axios"
import { envStorage } from "../util/localStorange"

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})

api.interceptors.request.use(
  async config => {
    let _authorization = getToken()
    if (_authorization)
      config.headers['authorization'] = _authorization
    config.headers['environment'] = envStorage()
    config.headers['Accept'] = 'application/json'
    return config
  },
  error => {
    Promise.reject(error)
  })

export const getRefresh = () => {
  let token = sessionStorage.getItem(`credentials-refresh-${process.env.PUBLIC_URL}`)
  if (token)
    return `bearer ${token}`
  return undefined
}


export const getToken = () => {
  let token = sessionStorage.getItem(`credentials-${process.env.PUBLIC_URL}`)
  if (token)
    return `Bearer ${token}`
  return undefined
}

export const setToken = (keycloak) => {
  sessionStorage.setItem(`credentials-${process.env.PUBLIC_URL}`, keycloak.token)
  sessionStorage.setItem(`credentials-refresh-${process.env.PUBLIC_URL}`, keycloak.refreshToken)
  return undefined
}

export default api
