import {
  DownOutlined,
  FileExcelOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Progress,
  Row,
  Select,
  Switch,
  TreeSelect,
} from "antd";
import ExportJsonExcel from "js-export-excel";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import searchIcon from "../../assets/icons/actions/search.svg";
import trash from "../../assets/icons/actions/trash.svg";
import { AuthContext } from "../../context/auth";
import api from "../../services/api";
import { Translate } from "../../translate";
import { optionsEntity, optionsEnum } from "../../util/handleItens";
import { datetime, datetimeFormat, toDateLocalNow } from "../../util/time";
import { messageError } from "../../util/messages";
import { findPath } from "../../util/location";

const Filter = ({
  search,
  loading,
  categories,
  locations,
  status,
  rfids,
  types,
}) => {
  const { setSearchItemId } = useContext(AuthContext);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [dataCsv, setDataCsv] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportPercent, setExportPercent] = useState(0);

  const handleSubmit = (values) => {
    search({
      ...handleValue(values),
    });
  };

  // const fetchLocationsTree = async () => {
  //   try {
  //     let { data } = await api.get("/maintenance/location/tree/");
  //     return data;
  //   } catch (error) {
  //     messageError(error);
  //   }
  // };

  const handleValue = (values) => {
    if (values.range) {
      values.start = values.range[0].toISOString();
      values.end = values.range[1].toISOString();
      values.range = [];
    }
    if (values.locations) values.locations = values.locations.toString();
    if (values.categories) values.categories = values.categories.toString();
    if (values.status) values.status = values.status.toString();
    if (values.rfids) values.rfids = values.rfids.toString();
    if (values.types) values.types = values.types.toString();
    values.enabled = values.enabledF;
    return values;
  };

  const exportToXls = async () => {
    try {
      setExportPercent(0);
      setExportLoading(true);
      let page = 0;
      let pageSize = 0;
      let rowPerPage = 50;
      var allData = [];
      // const locationTree = await fetchLocationsTree();
      do {
        let { data } = await api.get("/maintenance/maintenance", {
          params: {
            ...handleValue(form.getFieldsValue()),
            enabled: true,
            rowPerPage: rowPerPage,
            page: page,
          },
        });
        allData = [
          ...allData,
          ...data.content.map((element) => {
            return {
              local: findPath(locations, element.location.id),
              category: (element.categories || [])
                .map((i) => i.name)
                .toString(),
              status: element.status
                ? Translate.t(`maintenance.status.${element.status}`)
                : "",
              pin: element.rfid ? element.rfid.name : "",
              start: element.start ? datetime(element.start) : "",
              end: element.end ? datetime(element.end) : "",
              enabled: element.enabled
                ? Translate.t("action.yes")
                : Translate.t("action.no"),
              criateDate: element.creationDate
                ? datetime(element.creationDate)
                : "",
              id: element.id ? element.id : "",
            };
          }),
        ];
        page = data.number + 1;
        pageSize = data.totalPages;
        setExportPercent(parseInt((page / pageSize) * 100));
      } while (page < pageSize);

      new ExportJsonExcel({
        fileName: `maintenace-${toDateLocalNow()}`,
        datas: [
          {
            sheetData: allData,
            sheetName: `Events`,
            sheetFilter: [
              "local",
              "category",
              "status",
              "pin",
              "start",
              "end",
              "enabled",
              "criateDate",
              "id",
            ],
            sheetHeader: [
              Translate.t("common.location.title"),
              Translate.t("common.category.title"),
              Translate.t("common.status.title"),
              Translate.t("common.rfid.title"),
              Translate.t("maintenance.start.title"),
              Translate.t("maintenance.end.title"),
              Translate.t("common.enabled"),
              Translate.t("common.creationDate"),
              Translate.t("common.id.title"),
            ],
            columnWidths: [30, 10, 10, 10, 10, 10, 10, 10, 10],
          },
        ],
      }).saveExcel();
      setExportPercent(0);
    } finally {
      setExportLoading(false);
    }
  };

  const headers = [
    { label: Translate.t("common.location.title"), key: "local" },
    { label: Translate.t("common.category.title"), key: "category" },
    { label: Translate.t("common.status.title"), key: "status" },
    { label: Translate.t("common.rfid.title"), key: "pin" },
    { label: Translate.t("maintenance.start.title"), key: "start" },
    { label: Translate.t("maintenance.end.title"), key: "end" },
    { label: Translate.t("common.enabled"), key: "enabled" },
    { label: Translate.t("common.creationDate"), key: "criateDate" },
    { label: Translate.t("common.id.title"), key: "id" },
  ];

  const exportToCsv = async () => {
    try {
      setExportPercent(0);
      setExportLoading(true);
      let page = 0;
      let pageSize = 0;
      let rowPerPage = 50;
      var allData = [];
      // const locationTree = await fetchLocationsTree();
      do {
        let { data } = await api.get("/maintenance/maintenance", {
          params: {
            ...handleValue(form.getFieldsValue()),
            enabled: true,
            rowPerPage: rowPerPage,
            page: page,
          },
        });
        allData = [
          ...allData,
          ...data.content.map((element) => {
            return {
              local: findPath(locations, element.location.id),
              category: (element.categories || [])
                .map((i) => i.name)
                .toString(),
              status: element.status
                ? Translate.t(`maintenance.status.${element.status}`)
                : "",
              pin: element.rfid ? element.rfid.name : "",
              start: element.start ? datetime(element.start) : "",
              end: element.end ? datetime(element.end) : "",
              enabled: element.enabled
                ? Translate.t("action.yes")
                : Translate.t("action.no"),
              criateDate: element.creationDate
                ? datetime(element.creationDate)
                : "",
              id: element.id ? element.id : "",
            };
          }),
        ];
        page = data.number + 1;
        pageSize = data.totalPages;
        setExportPercent(parseInt((page / pageSize) * 100));
      } while (page < pageSize);
      setExportPercent(0);
      setDataCsv(allData);
    } catch {
      setExportLoading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <span
          key="xlsx"
          title={Translate.t("action.xlsx")}
          onClick={exportToXls}
        >
          {Translate.t("action.xlsx")}
        </span>
      ),

      icon: <FileExcelOutlined />,
    },
    {
      key: "2",
      label: (
        <span key="csv" title={Translate.t("action.csv")} onClick={exportToCsv}>
          {Translate.t("action.csv")}
        </span>
      ),
      icon: <FileTextOutlined />,
    },
  ];

  useEffect(() => {
    if (dataCsv.length > 0 || exportLoading) {
      var downloadCsv = document.getElementById("downloadCsv");
      downloadCsv.click();
      setDataCsv([]);
    }
    setExportLoading(false);
  }, [dataCsv]);

  return (
    <Col xs={24}>
      <Collapse
        defaultActiveKey={["1"]}
        className="card_accordion space-botton"
        bordered={false}
      >
        <Collapse.Panel
          className="reset"
          key="1"
          header={
            <span className="text-header primary-text-color">
              {Translate.t("title.search")}
            </span>
          }
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            className="card_form"
          >
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item name="id" label={Translate.t("common.id.title")}>
                  <Input placeholder={Translate.t("common.id.placeholder")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name="types"
                  label={Translate.t("common.type.title")}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={Translate.t("common.type.select")}
                    options={(optionsEnum(types) || []).map((i) => ({
                      ...i,
                      label: Translate.t(`maintenance.${i.value}`),
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name="rfids"
                  label={Translate.t("common.rfid.title")}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={Translate.t("common.rfid.select")}
                    options={optionsEntity(rfids)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name="status"
                  label={Translate.t("common.status.title")}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={Translate.t("common.status.select")}
                    options={(optionsEnum(status) || []).map((i) => ({
                      ...i,
                      label: Translate.t(`maintenance.status.${i.label}`),
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name="categories"
                  label={Translate.t("common.category.title")}
                >
                  <TreeSelect
                    allowClear
                    multiple
                    showSearch
                    placeholder={Translate.t("common.category.select")}
                    treeDefaultExpandAll
                    treeData={categories}
                    filterTreeNode={(search, item) => {
                      return (
                        item.title
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  name="locations"
                  label={Translate.t("common.location.title")}
                >
                  <TreeSelect
                    allowClear
                    multiple
                    showSearch
                    placeholder={Translate.t("common.location.select")}
                    treeDefaultExpandAll
                    treeData={locations}
                    filterTreeNode={(search, item) => {
                      return (
                        item.title
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={16} lg={12}>
                <Form.Item
                  name="range"
                  label={Translate.t("common.date.title")}
                >
                  <RangePicker
                    showTime={true}
                    format={datetimeFormat()}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={4} sm={4} md={8} lg={4} xl={4}>
                <Form.Item
                  name="enabledF"
                  valuePropName="checked"
                  initialValue={true}
                  label={Translate.t("common.enabled")}
                >
                  <Switch
                    checkedChildren={Translate.t("action.yes")}
                    unCheckedChildren={Translate.t("action.no")}
                    defaultChecked
                  />
                </Form.Item>
              </Col>

              <Col xs={20} sm={20} md={24} lg={20} xl={20}>
                <div className="btn-right">
                  <Row gutter={8}>
                    <Col>
                      <Button
                        icon={
                          <img
                            className="btn-img-icon"
                            src={trash}
                            alt="icon"
                          />
                        }
                        title={Translate.t("action.clear")}
                        onClick={() => {
                          setSearchItemId(undefined);
                          form.resetFields();
                        }}
                      >
                        <span className="no-mobile">
                          {Translate.t("action.clear")}
                        </span>
                      </Button>
                    </Col>
                    <Col>
                      <Dropdown menu={{ items }}>
                        <Button icon={<DownOutlined />} loading={exportLoading}>
                          <span className="no-mobile">
                            {exportLoading ? (
                              <Progress
                                size="small"
                                steps={5}
                                percent={exportPercent}
                              />
                            ) : (
                              Translate.t("action.export")
                            )}
                          </span>
                        </Button>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Button
                        icon={
                          <img
                            className="btn-img-icon"
                            src={searchIcon}
                            alt="icon"
                          />
                        }
                        title={Translate.t("action.search")}
                        loading={loading}
                        htmlType="submit"
                        type="primary"
                      >
                        <span className="no-mobile">
                          {Translate.t("action.search")}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <CSVLink
        visible={false}
        id="downloadCsv"
        filename={`maintenance-${toDateLocalNow()}.csv`}
        data={dataCsv}
        headers={headers}
      ></CSVLink>
    </Col>
  );
};

export default Filter;
