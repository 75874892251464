import { Col, Empty, Row, Spin } from "antd";

import React, { useContext, useEffect, useState } from "react";
import { Translate } from "../../translate";

import dayjs from "dayjs";
import bad from "../../assets/icons/actions/bad.svg";
import excellent from "../../assets/icons/actions/excellent.svg";
import good from "../../assets/icons/actions/good.svg";
import regular from "../../assets/icons/actions/regular.svg";
import { AuthContext } from "../../context/auth";
import api from "../../services/api";
import { messageError } from "../../util/messages";
import { number } from "../../util/number";

const Top4 = ({ start, end }) => {
  const { environment } = useContext(AuthContext);
  const [locais, setLocais] = useState([]);
  const [loading, setLoading] = useState(false);

  function reduzirFrase(frase) {
    if (frase.length <= 20) {
      return frase;
    }
    const palavras = frase.split(" ");
    let fraseReduzida = "";
    for (const palavra of palavras) {
      if (palavra.length > 3) {
        fraseReduzida += palavra.substring(0, 3) + ".";
      } else {
        fraseReduzida += palavra + " ";
      }
      if (fraseReduzida.length >= 20) {
        break;
      }
    }
    fraseReduzida = fraseReduzida.trim();
    return fraseReduzida;
  }

  const fetchCountSurvey = () => {
    setLoading(true);
    try {
      api
        .get("/survey/survey/group/local/grade/date", {
          params: {
            start: start
              ? dayjs(start).toISOString()
              : dayjs().utc().hour(0).minute(0).second(0).toISOString(),
            end: end ? dayjs(end).toISOString() : dayjs().utc().toISOString(),
          },
        })
        .then((response) => {
          handleDate(response.data);
        })
        .catch((error) => {
          messageError(error);
        });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDate = (value = []) => {
    value.forEach((i) => {
      i.grades = handleCalcula(i.grades, i.count);
    });
    setLocais(value);
  };

  const handleCalcula = (grades = [], count) => {
    let highestAverage = {
      count: -1,
    };
    grades.forEach((i) => {
      let avg = Number((i.count / count) * 100);
      if (avg > highestAverage.count) {
        highestAverage = i;
        highestAverage.avg = avg;
        highestAverage.count = avg;
      }
    });

    return highestAverage;
  };

  function smile(value) {
    if (value.grade === "bad")
      return (
        <div>
          <img className="smile-img-top" src={bad} alt="" />
          <span className="smile-text bad">{number(value.avg, 1)} %</span>
        </div>
      );
    if (value.grade === "regular")
      return (
        <div>
          <img className="smile-img-top" src={regular} alt="" />
          <span className="smile-text regular">{number(value.avg, 1)} %</span>
        </div>
      );
    if (value.grade === "good")
      return (
        <div>
          <img className="smile-img-top" src={good} alt="" />
          <span className="smile-text good">{number(value.avg, 1)} %</span>
        </div>
      );
    if (value.grade === "excellent")
      return (
        <div>
          <img className="smile-img-top" src={excellent} alt="" />
          <span className="smile-text excellent">{number(value.avg, 1)} %</span>
        </div>
      );
  }

  function line(data) {
    return (
      <Row className="card-top4" gutter={[8, 8]} style={{ marginTop: "20px" }}>
        <Col
          xs={8}
          title={data.local}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <span className="secondary-text-color">
            {reduzirFrase(data.local)}
          </span>
        </Col>
        <Col xs={7}>{smile(data.grades)}</Col>
        <Col
          xs={9}
          title={data.father}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <span className="secondary-text-color">
            {reduzirFrase(data.father)}
          </span>
        </Col>
      </Row>
    );
  }

  useEffect(() => {
    if (start && end) fetchCountSurvey();
  }, [start, end, environment]);

  return (
    <Row>
      <Col className="card_default" style={{ minHeight: "22em" }}>
        <div className="div-dash-itens">
          <span className="text-dashboard primary-text-color">
            {Translate.t("dashboard.top4")}
          </span>
          <Spin tip="Pesquisando" size="large" spinning={loading}>
            {locais && locais.length > 0 ? (
              locais.map((i) => line(i))
            ) : (
              <Empty
                style={{ marginTop: "10%" }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </Spin>
        </div>
      </Col>
    </Row>
  );
};

export default Top4;
