import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  theme,
  TreeSelect,
} from "antd";
import React, { useState } from "react";
import api from "../../services/api";
import { Translate } from "../../translate";
import { messageError, notification } from "../../util/messages";

const Register = ({ initialValues = {}, open, setOpen, search, locations }) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (values.id) {
        await api.put(`/control/hosthttp/${values.id}`, values);
        notification("success", "update", Translate.t("hosthttp.title"));
      } else {
        await api.post(`/control/hosthttp/`, values);
        notification("success", "save", Translate.t("hosthttp.title"));
      }
      setOpen(false);
      form.resetFields();
      search({});
    } catch (error) {
      messageError(error);
    } finally {
      setLoading(false);
    }
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title={
        <span>
          {initialValues.id
            ? Translate.t("action.edit", {
                name: Translate.t("hosthttp.title"),
              })
            : Translate.t("action.new", {
                name: Translate.t("hosthttp.title"),
              })}
        </span>
      }
      open={open}
      maskClosable={false}
      onCancel={() => onCancel()}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={16} className="default-padding">
          <Form.Item name="id">
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="deleted">
            <input type="hidden" />
          </Form.Item>
          <Col xs={24}>
            <Form.Item name="name" label={Translate.t("common.name.title")}>
              <Input
                placeholder={Translate.t("common.name.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="protocol"
              label={Translate.t("common.protocol.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.protocol.required"),
                },
              ]}
            >
              <Select
               placeholder={Translate.t("common.protocol.select")}
                options={[
                  { label: "http", value: "http" },
                  { label: "https", value: "https" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="host"
              label={Translate.t("common.host.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.host.required"),
                },
              ]}
            >
              <Input
                placeholder={Translate.t("common.host.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="port"
              label={Translate.t("common.port.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.port.required"),
                },
              ]}
            >
              <InputNumber min={0} max={65535} style={{ width: "100%" }}
                placeholder={Translate.t("common.port.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="path"
              label={Translate.t("common.path.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.path.required"),
                },
              ]}
            >
              <Input
                placeholder={Translate.t("common.path.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="token" label={Translate.t("common.token.title")}>
              <Input.Password
                style={{
                  borderRadius: token.borderRadius,
                  fontSize: token.fontSize,
                }}
                       autoComplete="off"
                placeholder={Translate.t("common.token.placeholder")}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="enabled"
              valuePropName="checked"
              label={Translate.t("common.enabled")}
            >
              <Switch
                checkedChildren={Translate.t("action.yes")}
                unCheckedChildren={Translate.t("action.no")}
                defaultChecked
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <div className="btn-right">
              <Button
                key="back"
                onClick={() => onCancel()}
                style={{ marginRight: "10px" }}
              >
                {Translate.t("action.cancel")}
              </Button>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => onOk()}
              >
                {Translate.t("action.ok")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default Register;
