const I18N_KEY = "i18nextLng"
const ENV = "env"
const THEME = "theme"
const COLLAPSED = "collapsed"

export const langangeStorage = () => {
    let language = localStorage.getItem(I18N_KEY)
    return language ? language : "pt"
}

export const setLangangeStorage = (value) => { localStorage.setItem(I18N_KEY, value) }

export const envStorage = () => {
    let env = localStorage.getItem(ENV)
    return env ? env : undefined
}

export const envRemoveStorage = () => {
    localStorage.removeItem(ENV)
}
export const setEnvStorage = (value) => { localStorage.setItem(ENV, value) }

export const themeStorage = () => {
    let theme = localStorage.getItem(THEME)
    return theme ? theme : undefined
}
export const setThemeStorage = (value) => { localStorage.setItem(THEME, value) }

export const collapsedStorage = () => {
    let collapsed = localStorage.getItem(COLLAPSED)
    return collapsed ? collapsed : undefined
}
export const setCollapsedStorage = (value) => { localStorage.setItem(COLLAPSED, value) }


export const getValueStorage = (key) => {
    let value = localStorage.getItem(key)
    return value ? value : undefined
}
export const setValueStorage = (key, value) => { localStorage.setItem(key, value) }