import { useKeycloak } from "@react-keycloak/web"
import { Badge, Col, Row, Select } from "antd"
import Search from "antd/es/transfer/search"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import dark from "../../assets/icons/actions/dark.svg"
import light from "../../assets/icons/actions/light.svg"
import logoDefault from "../../assets/icons/logo.svg"
import DropdownLanguage from "../../components/header"
import Bell from "../../components/header/bell"
import { AuthContext } from "../../context/auth"
import api, { getToken } from "../../services/api"
import { Translate } from "../../translate"
import { setEnvStorage } from "../../util/localStorange"
import { messageError } from "../../util/messages"

const Header = ({ collapsed, setSearchValue }) => {

  const { handleThemeChange, theme, environments, environment, setEnvironment, user } = useContext(AuthContext)
  const { keycloak } = useKeycloak()

  const [logo, setLogo] = useState()

  const fetchLogo = () => {
    api.get("/logo")
      .then((response) => { setLogo(response.data) })
      .catch((error) => {
        messageError(error)
        setLogo(logoDefault)
      })

  }

  const onChange = (e) => {
    let text = e.target.value
    if (text && text.length >= 3)
      setSearchValue(text)
    else
      setSearchValue(undefined)

  }

  useEffect(() => {
    fetchLogo()
  }, [environment])

  const handleProfile = () => {
    keycloak.accountManagement()
  }

  const handleTheme = (theme) => {
    if (theme === 'light')
      return light
    if (theme === 'dark')
      return dark
    /*    if (theme === 'mesc')
         return mesc */
  }

  return (
    <Row className="header-content">
      <Col xs={4} sm={8} md={8} lg={4} className="div-logo-content" >
        <table style={{ lineHeight: "normal", width: "100%" }}>
        <tbody>
          <tr>
            <td rowSpan='2' width={80}>
              <Link to="/" >
                <div className="div-logo">
                  <img
                    title="Home"
                    src={logo ? logo : logoDefault}
                    alt="Imagem logo"
                    className="logo" />
                </div>
              </Link>
            </td>
          </tr>
          </tbody>
        </table>
      </Col>
      <Col xs={10} sm={8} md={8} lg={8} className="no-mobile search-header">
        <Search placeholder={Translate.t("title.search_header")} onChange={(e) => onChange(e)}></Search>
      </Col>
      <Col xs={20} sm={8} md={8} lg={12} style={{ textAlign: "right" }} >
        <img src={handleTheme(theme)} style={{ cursor: "pointer" }} onClick={handleThemeChange} alt={theme} title={theme} />
        {
          environments && environments.length > 0 ?
            <span  className="select-header">
              <Select
                onChange={(newEnv) => {
                  setEnvironment(newEnv)
                  setEnvStorage(newEnv)
                }}
                value={environment}
                style={{ width: "auto", marginLeft: "10px" }}>
                {environments.sort(function (str1, str2) {
                  return str1.name.localeCompare(str2.name)
                }).map((env) => <Select.Option value={env.id} key={env.id}>{env.name}</Select.Option>)}
              </Select>
            </span>
            : Translate.t("error.env")
        }
        <span style={{ marginRight: "20px", marginLeft: "10px", cursor: "pointer" }}>
          {user?.profile ? (
            <Badge>
              <img
                alt="imagem perfil"
                title="Acessar perfil"
                onClick={handleProfile}
                style={{ borderRadius: "50%" }}
                width={65}
                height={65}
                src={
                  user?.profile
                    ? `${process.env.REACT_APP_API_URL}/storage/render/${user?.profile
                    }/${btoa(getToken())}`
                    : undefined
                }
                icon="user"
              />
            </Badge>
          ) : (
            <DropdownLanguage handleProfile={handleProfile} />
          )}
        </span>
        <Bell />
      </Col>
    </Row>
  )
}

export default Header
