import { Col, Empty, Row, Spin } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { AuthContext } from "../../../context/auth";
import api from "../../../services/api";
import { Translate } from "../../../translate";
import { messageError } from "../../../util/messages";
import { toDateLocalChart } from "../../../util/time";
import data from "../data.json";
import Statistic from "../smile/Statistic";

const Problems = ({ start, end }) => {
  const { environment } = useContext(AuthContext);
  const [series, setSeries] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSurveys = async () => {
    try {
      let { data } = await api.get("/survey/survey/group/count/category/date", {
        params: {
          enabled: true,
          start: start
            ? dayjs(start).toISOString()
            : dayjs().utc().hour(0).minute(0).second(0).toISOString(),
          end: end ? dayjs(end).toISOString() : dayjs().utc().toISOString(),
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const handleSeries = async () => {
    try {
      setLoading(true);
      let surveys = await fetchSurveys();
      let _dates = [];
      let _category = [];
      surveys = surveys.map((i) => ({ ...i, date: toDateLocalChart(i.date) }));
      surveys.forEach((i) => {
        if (!_dates.includes(i.date)) _dates.push(i.date);
        if (!_category.includes(i.category)) _category.push(i.category);
      });
      setCategorys(
        _dates.sort(function (a, b) {
          return new Date(b) - new Date(a);
        })
      );
      setSeries(
        _category.map((c) => ({
          name: c,
          data: _dates.map(
            (_date) =>
              surveys.find((i) => i.date === _date && i.category === c)
                ?.count || 0
          ),
        }))
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const dataFormat = (data) => {
    if (start && end && end.diff(start, "hours") < 27)
      return data.toLocaleTimeString();
    else return data.toLocaleDateString();
  };

  useEffect(() => {
    if (start && end) handleSeries();
  }, [start, end, environment]);

  return (
    <>
      <Col className="space-botton">
        <Statistic start={start} end={end} />
      </Col>
      <Row className="card_default space-botton" style={{ minHeight: "22.5em" }}>
        <Col xs={24} className="div-dash-itens">
          {series ? (
            <Spin tip="Pesquisando " size="large" spinning={loading}>
              <Chart
                options={{
                  ...data.options,
                  chart: {
                    toolbar: { show: false },
                  },
                  xaxis: {
                    type: "datetime",
                    labels: {
                      show: false,
                      formatter: function (value, timestamp) {
                        return dataFormat(new Date(timestamp));
                      },
                    },
                    categories: categorys,
                  },

                  tooltip: {
                    x: {
                      format: {
                        formatter: function (value, timestamp) {
                          return dataFormat(new Date(timestamp));
                        },
                      },
                    },
                  },
                }}
                series={series}
                type="area"
                height="220"
                width="100%"
              />
              <div
                className="secondary-text-color"
                style={{ textAlign: "center" }}
              >
                {Translate.t("dashboard.chart_problems")}
              </div>
            </Spin>
          ) : (
            <Empty
              style={{ marginTop: "10%" }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Problems;
