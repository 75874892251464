import { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined } from "@ant-design/icons"


export const optionsEnum = (data) => {
    return (data || []).map(i => ({ label: i, value: i }))
}

export const optionsEntity = (data) => {
    return (data || []).map(i => ({ label: i.name, value: i.id }))
}

export const optionsModule = (data) => {
    return (data || []).map(i => ({ label: i.serialNumber, value: i.id }))
}

export const optionsDirections = (data) => {
    return (data || []).map(i => ({ label: handleDirections(i), value: i }))
}

const handleDirections = (direction) => {
    switch (direction) {
        case "up":
            return <><ArrowUpOutlined /><span> Up</span></>
        case "down":
            return <><ArrowDownOutlined /><span> Down</span></>
        case "left":
            return <><ArrowLeftOutlined /><span> Left</span></>
        default:
            return <><ArrowRightOutlined /><span> Right</span></>
    }
}