import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Filter from "../../../components/reports/maintenance/Filter";
import List from "../../../components/reports/maintenance/List";
import { AuthContext } from "../../../context/auth";
import api from "../../../services/api";
import { Translate } from "../../../translate";
import { findPath } from "../../../util/location";
import { messageError } from "../../../util/messages";
import { number } from "../../../util/number";
import { datetimeSort, toDateLocalNow } from "../../../util/time";

const Maintenance = () => {
  const { environment } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [range, setRange] = useState([
    toDateLocalNow().subtract(1, "day"),
    toDateLocalNow(),
  ]);

  const fetchCategories = () => {
    api
      .get("/maintenance/category/tree/")
      .then((response) => {
        let _categories = response.data.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setCategories(_categories);
      })
      .catch((error) => messageError(error));
  };

  const fetchLocations = () => {
    api
      .get("/maintenance/location/tree/", { params: { disabled: true } })
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => messageError(error));
  };

  const search = async (values) => {
    try {
      setLoading(true);
      let { data } = await api.get(
        "/maintenance/maintenance/count/location/cat",
        {
          params: values,
        }
      );
      let _range = datetimeSort(range[0]) + "-" + datetimeSort(range[1]);
      if (data && data.length > 0) {
        data.forEach((element) => {
          element.value = number(element.value / 60, 1) + "Min";
          element.categories.forEach((i) => {
            i.value = number(i.value / 60, 1) + "Min";
          });
          element.range = _range;
          let location = findPath(locations, element.id);
          if (location) element.location = location;
        });
      }
      setData(data);
    } catch (error) {
      messageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
    fetchCategories();
  }, [environment]);

  return (
    <>
      <Row>
        <Col xs={24}>
          <span className="text-title primary-text-color">
            {" "}
            {Translate.t("menu.maintenance")}
          </span>
        </Col>
        <Col xs={24} style={{ marginTop: "10px" }}>
          <span className="text-sub secondary-text-color">
            {" "}
            {Translate.t("title.info.maintenance_report")}
          </span>
        </Col>
      </Row>
      <Filter
        search={search}
        loading={loading}
        locations={locations}
        range={range}
        setRange={setRange}
        data={data}
        categories={categories}
      />
      <List data={data} loading={loading} categories={categories} />
    </>
  );
};

export default Maintenance;
