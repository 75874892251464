
import { Col, Input, Tree } from "antd"
import React, { useEffect, useState } from "react"
import { Translate } from "../../translate"


const SearchTree = ({ locations, onSelectTree, selectedKeys, defaultExpandedKeys }) => {

    const { TreeNode } = Tree
    const { Search } = Input
    const [expandedKeys, setExpandedKeys] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [autoExpandParent, setAutoExpandParent] = useState(true)

    const dataList = []
    const generateList = (data) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i]
            const { key } = node
            const { title } = node
            dataList.push({ key: key, title: title })
            if (node.children) {
                generateList(node.children)
            }
        }
    }

    generateList(locations)

    const getParentKey = (key, tree) => {
        let parentKey
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i]
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children)
                }
            }
        }
        return parentKey
    }

    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    }

    const onChange = (e) => {
        const { value } = e.target
        const _expandedKeys = dataList
            .map(item => {
                if (item.title.indexOf(value) > -1) {
                    return getParentKey(item.key, locations)
                }
                return null
            })
            .filter((item, i, self) => item && self.indexOf(item) === i)
        setExpandedKeys(_expandedKeys)
        setSearchValue(value)
        setAutoExpandParent(true)
    }

    const loop = (data) => {
        return data.map(item => {
            const index = item.title.indexOf(searchValue)
            const beforeStr = item.title.substr(0, index)
            const afterStr = item.title.substr(index + searchValue.length)
            const title =
                index > -1 ? (
                    <span>
                        {beforeStr}
                        <span style={{ color: '#f50' }}>{searchValue}</span>
                        {afterStr}
                    </span>
                ) : (
                    <span>{item.title}</span>
                )
            if (item.children) {
                return (
                    <TreeNode key={item.key} title={title}>
                        {loop(item.children)}
                    </TreeNode>
                )
            }
            return <TreeNode key={item.key} title={title} />
        })
    }

    useEffect(() => {
        if (locations.length > 0)
            setExpandedKeys(defaultExpandedKeys)
    }, [locations])

    return (
        < >
            <Col xs={24} style={{ padding: "0 2px 8px" }}>
                <Search placeholder={Translate.t("common.location.placeholder")} onChange={onChange} />
            </Col>
            <Col xs={24} style={{ padding: "0 2px 8px", marginTop: "8px", overflow: "auto", height: "65vh" }}>
                <Tree style={{ width: "100%" }}
                    showLine
                    defaultExpandAll={true}
                    onExpand={onExpand}
                    expandedKeys={(expandedKeys || selectedKeys)}
                    autoExpandParent={autoExpandParent}
                    onSelect={onSelectTree}
                    selectedKeys={selectedKeys}
                >
                    {loop(locations)}
                </Tree>
            </Col>
        </>
    )
}

export default SearchTree