import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Switch, Upload } from "antd"
import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { Translate } from "../../translate"
import { messageError, notification } from "../../util/messages"

import { optionsEnum } from "../../util/handleItens"
const Register = ({ initialValues = {}, open, setOpen, search, audiences, types }) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [img, setImg] = useState(false)

    const onFinish = async (values) => {
        try {
            setLoading(true)
            if (values.id) {
                await api.put(`/maintenance/category/${values.id}`, values)
                notification("success", "update", Translate.t("common.category.title"))
            }
            else {
                await api.post(`/maintenance/category/`, values)
                notification("success", "save", Translate.t("common.category.title"))
            }
            form.resetFields()
            setOpen(false)
            search({})
        }
        catch (error) {
            messageError(error)
        }
        finally {
            setLoading(false)
        }

    }

    const onOk = () => {
        form.submit()
    }

    const onCancel = () => {
        setOpen(false)
        form.resetFields()
    }

    const handleImage = (id) => {
        api.get(`/maintenance/files/render/${id}`).then((response) => {
            if (response.data.content) {
                setImg(`data:${response.data.contentType};base64,${response.data.content}`)
            }
        })
    }

    useEffect(() => {
        if (initialValues.icon)
            handleImage(initialValues.icon)
    }, [initialValues])

    const upload = async (request, env) => {
        try {
            let formData = new FormData()
            formData.append('file', request.file)
            let response = await api.post('/maintenance/files/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            handleRemove()
            form.setFieldValue("icon", response.data)
            handleImage(response.data)
            request.onSuccess()
        } catch (error) {

        }
    }

    const handleRemove = async () => {
        if (form.getFieldValue("icon"))
            await api.delete(`/maintenance/files/${form.getFieldValue("icon")}`)
    }

    const onRemove = (file) => {
        setImg(undefined)
        handleRemove()
        form.setFieldValue("icon", undefined)
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <Button icon={<UploadOutlined />}>{Translate.t("common.upload")}</Button>}
        </div>
    )

    return (
        <Modal
            title={<span>{initialValues.id ? Translate.t("action.edit", { name: Translate.t("common.category.title") }) : Translate.t("action.new", { name: Translate.t("common.category.title") })}</span>}
            open={open}
            maskClosable={false}
            onCancel={() => onCancel()}
            footer={null}>
            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues} >
                <Row className="default-padding">
                    <Form.Item name="id" >
                        <input type="hidden" />
                    </Form.Item>
                    <Form.Item name="deleted" >
                        <input type="hidden" />
                    </Form.Item>
                    <Col xs={24}>
                        <Form.Item name="icon" label={Translate.t("common.icon")} >
                            <input type="hidden" />
                            <Upload
                                name={'file'}
                                maxCount={1}
                                multiple={false}
                                customRequest={(request) => upload(request)}
                                accept={['jpg', 'png', 'jpeg', 'svg'].map((e) => `.${e}`).join(', ')}
                                onRemove={onRemove}
                            >
                                {img ? (<>
                                    <div className="div-category-icon" >
                                        <img
                                            src={img}
                                            alt="avatar"
                                            width="75"
                                            height="75"
                                            title={Translate.t("common.iconChange")}
                                        />

                                    </div>
                                    <span className="secondary-text-color">{Translate.t("common.iconBackground")}</span></>
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="name" label={Translate.t("common.name.title")}
                            rules={[{ required: true, message: Translate.t("common.name.required") }]}
                        >
                            <Input placeholder={Translate.t("common.name.placeholder")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="nameTranslated" label={Translate.t("category.nameTranslated")}
                            rules={[{ required: true, message: Translate.t("common.name.required") }]}
                        >
                            <Input placeholder={Translate.t("common.name.placeholder")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="audiences" label={Translate.t("location.audience.title")}
                            rules={[{ required: true, message: Translate.t("location.audience.required") }]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder={Translate.t("location.audience.select")}
                                options={(optionsEnum(audiences) || []).map(i => ({ ...i, label: Translate.t(`location.${i.value}`) }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="type" label={Translate.t("maintenance.type.title")}
                            rules={[{ required: true, message: Translate.t("maintenance.type.required") }]}
                        >
                            <Select
                                allowClear
                                placeholder={Translate.t("maintenance.type.select")}
                                options={(optionsEnum(types) || []).map(i => ({ ...i, label: Translate.t(`maintenance.${i.value}`) }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="notify" label={Translate.t("category.notify.title")}
                        >
                            <InputNumber style={{ width: "100%" }} placeholder={Translate.t("category.notify.placeholder")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item name="itsPublic" key="FormitsPublic" valuePropName="checked" label={Translate.t("common.public")}
                        >
                            <Switch defaultChecked key="SwitchPublic" checkedChildren={Translate.t("action.yes")} unCheckedChildren={Translate.t("action.no")} />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item name="enabled" valuePropName="checked" label={Translate.t("common.enabled")}
                        >
                            <Switch defaultChecked checkedChildren={Translate.t("action.yes")} unCheckedChildren={Translate.t("action.no")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <div className="btn-right">
                            <Button key="back" onClick={() => onCancel()} style={{ marginRight: "10px" }}>
                                {Translate.t("action.cancel")}
                            </Button>
                            <Button key="submit" type="primary" loading={loading} onClick={() => onOk()}>
                                {Translate.t("action.ok")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default Register