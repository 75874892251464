import { Col, Row, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";

import bad from "../../../assets/icons/actions/bad.svg";
import regular from "../../../assets/icons/actions/regular.svg";
import excellent from "../../../assets/icons/actions/excellent.svg";
import good from "../../../assets/icons/actions/good.svg";
import api from "../../../services/api";
import dayjs from "dayjs";
import { messageError } from "../../../util/messages";
import { number } from "../../../util/number";
import "./style.css";
import { sync } from "framer-motion";

const Statistic = ({ start, end }) => {
  const { environment } = useContext(AuthContext);
  const [groupGrade, setGroupGrade] = useState();
  const [countSurvey, setCountSurvey] = useState();
  const [loading, setLoading] = useState(false);

  const fetchGroupGrade = async () => {
    try {
      let { data } = await api.get("/survey/survey/group/grade", {
        params: {
          start: start ? dayjs(start).toISOString() : undefined,
          end: end ? dayjs(end).toISOString() : undefined,
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const fetchCountSurvey = async () => {
    try {
      let { data } = await api.get("/survey/survey/count/date", {
        params: {
          start: start ? dayjs(start).toISOString() : undefined,
          end: end ? dayjs(end).toISOString() : undefined,
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const handlePercentDiv = (type) => {
    if (groupGrade && countSurvey) {
      let grade = groupGrade.find((i) => i.grade === type);
      if (grade) {
        let value = parseInt(
          number((grade.count / countSurvey.count) * 100, 2)
        );
        if (value < 1) return `div-${type} p0`;
        if (value > 0 && value < 10) return `div-${type} p10`;
        if (value >= 10 && value < 20) return `div-${type} p20`;
        if (value >= 20 && value < 30) return `div-${type} p30`;
        if (value >= 30 && value < 40) return `div-${type} p40`;
        if (value >= 40 && value < 50) return `div-${type} p50`;
        if (value >= 50 && value < 60) return `div-${type} p60`;
        if (value >= 60 && value < 70) return `div-${type} p70`;
        if (value >= 80 && value < 90) return `div-${type} p80`;
        if (value >= 90 && value < 100) return `div-${type} p90`;
        if (value >= 100) return `div-${type} p100`;
      }
    }
  };

  const handlePercent = (type) => {
    if (groupGrade && countSurvey) {
      let grade = groupGrade.find((i) => i.grade === type);
      if (grade) {
        const percentage = Math.round((grade.count / countSurvey.count) * 100);
        return `${percentage} %`;
      }
    }
    return "0 %";
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        let groupGrade = await fetchGroupGrade();
        let countSurvey = await fetchCountSurvey();
        setCountSurvey(countSurvey);
        setGroupGrade(groupGrade);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    if (start && end) fetchData();
  }, [start, end, environment]);

  return (
    <Row>
      <div style={{ width: "100%", display: "block" }}>
        <Spin tip="Pesquisando" size="large" spinning={loading}>
          <div className="circular-content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className={handlePercentDiv("excellent")}>
                      <div className="circle-wrap">
                        <div className="circle">
                          <div className="mask full">
                            <div className="fill"></div>
                          </div>
                          <div className="mask half">
                            <div className="fill"></div>
                          </div>
                          <div className="inside-circle">
                            <img src={excellent} alt=""></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="smile-text excellent">
                      {handlePercent("excellent")}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="circular-content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className={handlePercentDiv("good")}>
                      <div className="circle-wrap">
                        <div className="circle">
                          <div className="mask full">
                            <div className="fill"></div>
                          </div>
                          <div className="mask half">
                            <div className="fill"></div>
                          </div>
                          <div className="inside-circle">
                            <img src={good} alt=""></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="smile-text good">
                      {handlePercent("good")}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="circular-content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className={handlePercentDiv("regular")}>
                      <div className="circle-wrap">
                        <div className="circle">
                          <div className="mask full">
                            <div className="fill"></div>
                          </div>
                          <div className="mask half">
                            <div className="fill"></div>
                          </div>
                          <div className="inside-circle">
                            <img src={regular} alt=""></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="smile-text regular">
                      {handlePercent("regular")}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="circular-content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className={handlePercentDiv("bad")}>
                      <div className="circle-wrap">
                        <div className="circle">
                          <div className="mask full">
                            <div className="fill"></div>
                          </div>
                          <div className="mask half">
                            <div className="fill"></div>
                          </div>
                          <div className="inside-circle">
                            <img src={bad} alt=""></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="smile-text bad">
                      {handlePercent("bad")}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Spin>
      </div>
    </Row>
  );
};

export default Statistic;
