import React from "react";
import ThreeDotsWave from "./ThreeDotsWave";
const Loading = ({ size, direction }) => {
  size = size === undefined ? "10em" : size
  direction = direction === undefined ? "center" : direction
  return (
    <Grid size={size} direction={direction}>
      <ThreeDotsWave />
    </Grid>
  );
};

function Grid({ children, size, direction }) {
  return (
    <div
      className="loading-grid"
      style={{
        justifyContent: direction,
        alignItems: direction,
        minHeight: size,
      }}
    >
      <LoadingBox>{children}</LoadingBox>
    </div>
  );
}

function LoadingBox({ children }) {
  return React.Children.map(children, (child) => {
    return <div>{child}</div>;
  });
}

export default Loading;
