import { Button, Col, Row } from "antd"
import React, { useContext, useEffect, useState } from "react"
import rfid from "../../assets/icons/actions/rfid.svg"
import Filter from "../../components/rfid/Filter"
import List from "../../components/rfid/List"
import { AuthContext } from "../../context/auth"
import api from "../../services/api"
import { Translate } from "../../translate"
import { messageError } from "../../util/messages"

const Rfid = () => {

    const { environment ,searchItemId} = useContext(AuthContext)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({})
    const [params, setParams] = useState({ page: 0, rowPerPage: 5, enabled: true, order: "desc" })
    const [locations, setLocations] = useState([])
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState()
    const [types, setTypes] = useState([])

    const fetchTypes = () => {
        api.get("/maintenance/pin/types")
            .then((response) => { setTypes(response.data) })
            .catch((error) => messageError(error))
    }

    const handleNew = () => {
        setOpen(true)
        setItem({ enabled: true, deleted: false })
    }

    const fetchLocations = () => {
        api.get("/maintenance/location/tree/")
            .then((response) => { setLocations(response.data) })
            .catch((error) => messageError(error))
    }

    const search = (values) => {
        setLoading(true)
        let _params = {
            ...params,
            ...values || {}
        }
        setParams(_params)
        api.get("/maintenance/pin/", { params: _params })
            .then((response) => {
                setData(response.data.content)
                setPagination({
                    current: response.data.number + 1,
                    pageSize: response.data.size,
                    total: response.data.totalElements
                })
            })
            .catch((error) => {
                messageError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(()=>{
        fetchLocations()
        fetchTypes()
    }, [environment])

    useEffect(() => {
        if (searchItemId)
            search({ id: searchItemId, page: 0, rowPerPage: 5,  order: "desc" })
    }, [searchItemId])

    return (

        <>
            <Row>
                <Col xs={24} className="space-botton">
                    <span className="text-title primary-text-color" > {Translate.t("menu.rfid")}</span>
                </Col>
                <Col xs={18} sm={16} md={24} lg={12} className="space-botton">
                    <span className="text-sub secondary-text-color" >{Translate.t("title.info.rfid")}</span>
                </Col>
                <Col xs={6} sm={8} md={10} lg={12} className="alng-rigth space-botton">
                    <Button type={"primary"} icon={<img className="btn-img-icon" src={rfid} alt="icon" />} onClick={handleNew} title={Translate.t("action.new", { name: Translate.t("rfid.title") })} >
                        <span className="no-mobile"> {Translate.t("action.new", { name: Translate.t("rfid.title") })}</span>
                    </Button>
                </Col>
            </Row>
            <Filter
                search={search}
                loading={loading}
                locations={locations}
                types={types}
            />
            <List
                search={search}
                pagination={pagination}
                data={data}
                loading={loading}
                locations={locations}
                open={open}
                setOpen={setOpen}
                item={item}
                setItem={setItem}
                types={types}
            />
        </>
    )
}

export default Rfid