import { LoadingOutlined } from "@ant-design/icons"
import { useKeycloak } from "@react-keycloak/web"
import { Button, Layout } from "antd"
import React, { useContext, useEffect, useState } from "react"
import collapse from "../assets/icons/actions/colapse.svg"
import logoutSvg from "../assets/icons/actions/logout.svg"
import { AuthContext } from "../context/auth"
import { Translate } from "../translate"
import { collapsedStorage, setCollapsedStorage } from "../util/localStorange"
import ContentRoutes from "./content"
import HeaderContent from "./header"
import Menu from "./menu"
import SearchResult from "../components/search"


const ContentPage = () => {

  const { logout } = useContext(AuthContext)
  const { keycloak } = useKeycloak()
  const { Header, Sider, Content } = Layout
  const [collapsed, setCollapsed] = useState(window.screen.width > 900 ? false : true)
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState()


  const handleCollapsed = () => {
    let _collapsed = !collapsed
    setCollapsedStorage(_collapsed ? 'open' : 'close')
    setCollapsed(_collapsed)
  }

  useEffect(() => {
    let _collapsed = collapsedStorage()
    if (_collapsed !== undefined)
      if (_collapsed === 'open')
        setCollapsed(true)
      else
        setCollapsed(false)

  }, [])

  return (
    <Layout className="layout-main">
      <Header className="layout-header" >
        <HeaderContent collapsed={collapsed} setSearchValue={setSearchValue} />
      </Header>
      <Layout className="layout-container" style={collapsed ? { gridTemplateColumns: "75px auto" } : { gridTemplateColumns: "250px auto" }} >
        <Layout className="layout-menu" >
          <form
            id="post-logout"
            name="post-logout"
            action={`${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`}
            method="post">
            <input type="hidden" name="client_id" value={keycloak.clientId} />
            <input type="hidden" name="refresh_token" id="refresh_token" value={keycloak.refreshToken} />
          </form>
          <Sider className="silder-menu"
            width={230}
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth="54"
            breakpoint="md"
          >
            <Menu />
            <div className={collapsed ? "div-logout-content-close" : "div-logout-content"} >
              <Button className="div-logout" loading={loading}
                title={Translate.t('action.logout', { name: "" })}
                onClick={() => {
                  setLoading(true);
                  document.getElementById("post-logout").submit()
                  logout()
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }}
              >
                <img className="btn-img-icon"
                  title="logout"
                  src={logoutSvg}
                  style={{
                    width: "20px",
                  }}
                  alt="logout"
                />
                {!collapsed && Translate.t('action.logout', { name: "" })}
              </Button>
              {!collapsed &&
                <div className="div-footer">Bright ©2023</div>}
            </div>
            <div className={collapsed ? "collapse collapse-close no-mobile" : "collapse collapse-open"} onClick={() => handleCollapsed()}>
              <img className="collapse-img"
                title="collapse"
                src={collapse}
                alt="collapse"
              />
            </div>
          </Sider>
        </Layout>
        <Layout className="layout-content"  >
          <Content className="content-main">
            {searchValue && <SearchResult searchValue={searchValue} setSearchValue={setSearchValue} />}
            {!searchValue && <ContentRoutes />}
          </Content>
        </Layout>
      </Layout>
    </Layout>)
}

export default ContentPage;
