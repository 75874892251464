import { languages as ptBr } from "./pt-BR"
import { languages as pt } from "./pt"
import { languages as en } from "./en"

const languages = {
    ...ptBr,
    ...pt,
    ...en
}

export { languages }