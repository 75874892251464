import 'antd/dist/reset.css'
import React from "react"
import './App.css'
import AppRoutes from './AppRoutes'
import '../src/themes/light.css'

function App() {
  return <AppRoutes />
}

export default App
