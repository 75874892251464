import { UserOutlined } from "@ant-design/icons"
import { Avatar, Popover, Select, Space } from "antd"
import { useContext, useState } from "react"
import { AuthContext } from "../../context/auth"
import { Translate } from "../../translate"
import { langangeStorage, setLangangeStorage } from "../../util/localStorange"

const DropdownLanguage = ({ handleProfile }) => {

    const { user } = useContext(AuthContext)

    const languages = ["en", "pt", "pt-BR"]

    const [language, setLanguage] = useState(langangeStorage)

    const handleSelectLanguange = (value) => {
        setLanguage(value)
        setLangangeStorage(value)
        window.location = window.location
    }

    const items = (
        <ul className="header-drop-ul secondary-text-color menu-user" >
            <li className="text-header primary-text-color menu-user" >
                {user?.name}
            </li>
            {user?.email && <li className="text-header primary-text-color menu-user" >
                {user?.email}
            </li>}
            <li>

                <span title={Translate.t("common.profile")} onClick={handleProfile}>{Translate.t("common.profile")}</span>
            </li>
            <li className="select-header" >
                <Select 
                    value={language}
                    style={{ width: "100%" }}
                    bordered={false}
                    onSelect={(value) => handleSelectLanguange(value)}>
                    {languages.map(language =>
                        <Select.Option key={language} value={language}>{language}</Select.Option>)}
                </Select>
            </li>
        </ul>)
    return (
        <Popover placement="bottomRight" overlayStyle={{
            width: "200px"
        }} content={items}
            trigger="click">
            <Avatar size={44} icon={<UserOutlined />} />
        </Popover>
    )

}

export default DropdownLanguage