import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select } from "antd"
import React, { useContext } from "react"
import searchIcon from "../../assets/icons/actions/search.svg"
import trash from "../../assets/icons/actions/trash.svg"
import { AuthContext } from "../../context/auth"
import { Translate } from "../../translate"
import { datetimeFormat } from "../../util/time"

const Filter = ({ search, loading }) => {

    const { setSearchItemId } = useContext(AuthContext)
    const { RangePicker } = DatePicker
    const [form] = Form.useForm()

    const handleSubmit = (values) => {
        if (values.range) {
            values.start = values.range[0].toISOString()
            values.end = values.range[1].toISOString()
            values.range = []
        }
        values.enabled= values.enabledF 
        values.regex = true
        search({
            ...values
        })
    }

    let options = [
        { label: Translate.t("device.authorized"), value: true },
        { label: Translate.t("device.blocked"), value: false },
    ]

    return (
        <Col xs={24}>
            <Collapse defaultActiveKey={['1']} className="card_accordion space-botton" bordered={false}>
                <Collapse.Panel className="reset" key="1" header={<span className="text-header primary-text-color">{Translate.t("title.search")}</span>}>
                    <Form form={form} onFinish={handleSubmit} layout="vertical" className="card_form" >
                        <Row gutter={[8,0]}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="id" label={Translate.t("common.id.title")}>
                                    <Input placeholder={Translate.t("common.id.placeholder")} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="deviceId" label={Translate.t("device.deviceId.title")}>
                                    <Input placeholder={Translate.t("device.deviceId.placeholder")} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="brand" label={Translate.t("device.brand.title")}>
                                    <Input placeholder={Translate.t("device.brand.placeholder")} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="enabledF" initialValue={undefined} label={Translate.t("common.status.title")}
                                >
                                    <Select
                                        allowClear
                                        defaultActiveFirstOption={false}
                                        style={{ width: '100%' }}
                                        placeholder={Translate.t("common.status.select")}
                                        options={options}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={12}>
                                <Form.Item name="range" label={Translate.t("common.lastConnection")}>
                                    <RangePicker showTime={true} format={datetimeFormat()} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12}>
                                <div className="btn-right">
                                    <Row gutter={8}>
                                        <Col>
                                        <Button icon={<img className="btn-img-icon" src={trash} alt="icon" />}
                                                title={Translate.t("action.clear")} onClick={() => {
                                                    setSearchItemId(undefined)
                                                    form.resetFields()
                                                }} >
                                                <span className="no-mobile">{Translate.t("action.clear")}</span>
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button icon={<img className="btn-img-icon" src={searchIcon} alt="icon" />}
                                                title={Translate.t("action.search")} loading={loading} htmlType="submit" type="primary" >
                                                <span className="no-mobile">{Translate.t("action.search")}</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
            </Collapse>
        </Col>
    )
}

export default Filter