import { Button, Col, Collapse, Form, Input, Row, Select, Switch } from "antd"
import React, { useContext } from "react"
import searchIcon from "../../assets/icons/actions/search.svg"
import trash from "../../assets/icons/actions/trash.svg"
import { AuthContext } from "../../context/auth"
import { Translate } from "../../translate"
import { optionsEnum } from "../../util/handleItens"

const Filter = ({ search, loading, audiences, types }) => {

    const { setSearchItemId } = useContext(AuthContext)
    const [form] = Form.useForm()

    const handleSubmit = (values) => {
        if (values.audiences)
            values.audiences = values.audiences.toString()
            values.itsPublic= values.itsPublicF 
            values.enabled= values.enabledF 
        if (values.types)
            values.types = values.types.toString()
        values.regex = true
        search({
            ...values
        })
    }

    return (
        <Col xs={24}>
            <Collapse defaultActiveKey={['1']} className="card_accordion space-botton" bordered={false}>
                <Collapse.Panel className="reset" key="1" header={<span className="text-header primary-text-color">{Translate.t("title.search")}</span>}>
                    <Form form={form} onFinish={handleSubmit} layout="vertical" className="card_form" >
                    <Row gutter={[8,0]}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="id" label={Translate.t("common.id.title")}>
                                    <Input placeholder={Translate.t("common.id.placeholder")} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="name" label={Translate.t("common.name.title")}>
                                    <Input placeholder={Translate.t("common.name.placeholder")} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="audiences" label={Translate.t("location.audience.title")}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder={Translate.t("location.audience.select")}
                                        options={(optionsEnum(audiences) || []).map(i => ({ ...i, label: Translate.t(`location.${i.value}`) }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="types" label={Translate.t("maintenance.type.title")}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder={Translate.t("maintenance.type.select")}
                                        options={(optionsEnum(types) || []).map(i => ({ ...i, label: Translate.t(`maintenance.${i.value}`) }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Form.Item name="itsPublicF" initialValue={true} valuePropName="checked" label={Translate.t("common.public")}
                                >
                                    <Switch defaultChecked checkedChildren={Translate.t("action.yes")} unCheckedChildren={Translate.t("action.no")} />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Form.Item name="enabledF" initialValue={true} valuePropName="checked" label={Translate.t("common.enabled")}
                                >
                                    <Switch defaultChecked checkedChildren={Translate.t("action.yes")} unCheckedChildren={Translate.t("action.no")} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div className="btn-right">
                                    <Row gutter={8}>
                                        <Col>
                                        <Button icon={<img className="btn-img-icon" src={trash} alt="icon" />}
                                                title={Translate.t("action.clear")} onClick={() => {
                                                    setSearchItemId(undefined)
                                                    form.resetFields()
                                                }} >
                                                <span className="no-mobile">{Translate.t("action.clear")}</span>
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button icon={<img className="btn-img-icon" src={searchIcon} alt="icon" />}
                                                title={Translate.t("action.search")} loading={loading} htmlType="submit" type="primary" >
                                                <span className="no-mobile">{Translate.t("action.search")}</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
            </Collapse>
        </Col>
    )
}

export default Filter