import { useKeycloak } from "@react-keycloak/web"
import { ConfigProvider } from "antd"
import React, { createContext, useEffect, useState } from "react"
import Loading from "../components/loading"
import { setToken } from "../services/api"
import { envRemoveStorage, envStorage, langangeStorage, setEnvStorage, setThemeStorage, themeStorage } from "../util/localStorange"
import handleLocales from "../util/locales"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

  const { initialized, keycloak } = useKeycloak()
  const [user, setUser] = useState(null)
  const [environment, setEnvironment] = useState()
  const [environments, setEnvironments] = useState([])
  const [loading, setLoading] = useState(false)
  const [theme, setTheme] = useState('light')
  const [notification, setNotification] = useState([])
  const [searchItemId, setSearchItemId] = useState()

  const hasAccess = (...args) => {
    let { authorities } = keycloak.tokenParsed
    if (authorities) {
      if (authorities.includes("admin"))
        return true
      for (var i = 0; i < args.length; i++) {
        let arg = (args[i] || '').split(',')
        for (var j = 0; j < arg.length; j++) {
          let role = arg[j]
          if (authorities.includes(role))
            return true
        }
      }
      return
    }
    return false
  }

  const handleUpdate = () => {
    if (keycloak.authenticated) {
      keycloak.updateToken(60).then(function (refreshed) {
        if (refreshed) {
          handleToken()
        }
      }).catch(function () {
        keycloak.clearToken()
        keycloak.logout()
      })
    } else {
      keycloak.clearToken()
      keycloak.logout()
    }
  }

  function handleThemeChange() {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
    setThemeStorage(newTheme)
    document.documentElement.setAttribute('data-theme', newTheme)
  }

  const logout = () => {
    sessionStorage.removeItem(`credentials-${process.env.PUBLIC_URL}`)
    sessionStorage.removeItem(`credentials-refresh-${process.env.PUBLIC_URL}`)
  }

  const handleToken = () => {
    let _envStore = envStorage()
    let _user = { name: keycloak.tokenParsed.name, id: keycloak.tokenParsed.sub, authorities: keycloak.tokenParsed.authorities }
    let _environments = (keycloak.tokenParsed.environments || []).sort(function (str1, str2) {
      return str1.name.localeCompare(str2.name)
    })
    setUser(_user)
    setToken(keycloak)
    setEnvironments(_environments)
    let environment = _envStore && _environments.length > 0 ? _environments.find((e) => e.id === _envStore).id : (_environments.length > 0 ? _environments[0].id : undefined)
    if (environment) {
      setEnvironment(environment)
      setEnvStorage(environment)
    } else {
      setEnvironment(undefined)
      envRemoveStorage()
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleUpdate()
    }, 1000 * 60)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    let _theme = themeStorage()
    if (_theme) {
      setTheme(_theme)
      document.documentElement.setAttribute('data-theme', _theme)
    }
  }, [])


  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        handleToken()
        setLoading(false)
      }
    } else {
      setLoading(true)
    }
  }, [initialized])

  if (!initialized) {
    return <Loading />
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!keycloak.authenticated,
        user,
        loading,
        environment,
        logout,
        hasAccess,
        environments,
        setEnvironment,
        handleThemeChange,
        theme,
        notification,
        setNotification,
        searchItemId,
        setSearchItemId
      }}
    >
      <ConfigProvider theme={theme} locale={handleLocales(langangeStorage)}>
        {children}
      </ConfigProvider>
    </AuthContext.Provider>
  )
}
