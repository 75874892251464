export const currency = (value) => {
    return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 4
    }).format(value);
}

export const number = (value, digits) => {
    return (value || 0).toLocaleString(
        undefined,
        { maximumFractionDigits: digits ? digits : 4 }
    )
}