const languages = {
    en: {
        translations: {
            title: {
                search: "Search",
                search_result: "Search results",
                search_header: "Type to search for documents, employees, etc.",
                info: {
                    camera: "General information about the sensors",
                    survey: "Assessment report",
                    local: "General information about the places",
                    rfid: "General information about RFIDs",
                    maintenance: "General information about maintenance",
                    maintenance_category:"General information about maintenance categories",
                    device: "General information about devices",
                    dashboard: "All basic information on this screen",
                    statistics: "General information about Statistics",
                    hosthttp: "General information about alarm servers",
                    maintenance_report: "Maintenance report",
                    visits: "Visits report",
                    alert: "Alerts Report",
                }
            },
            common: {
                profile:"My profile",
                public: "Display to users",
                add: "Add {{name}}",
                action: "Action",
                count: "Count",
                icon: "Image",
                iconChange: "Change image",
                iconBackground: "The image is on a transparent background",
                upload: "Upload",
                enabled: "Enabled",
                deleted: "Deleted",
                all: "All",
                back: "Back",
                creationDate: "Creation date",
                lastConnection: "Last connection",
                selectInfo: "Select the information",
                selectAction: "Select the action",
                selectWarn: "Select information from the table",
                start: {
                    title: "Start",
                    placeholder: "Enter the start",
                    select: "Select the start",
                    required: "Start is required",
                },
                end: {
                    title: "End",
                    placeholder: "Enter the end",
                    select: "Select the end",
                    required: "End is required",
                },
                id: {
                    title: "Id",
                    placeholder: "Enter the id",
                    select: "Select the id",
                    required: "Id is required",
                },
                date: {
                    title: "Date",
                    placeholder: "Enter the date",
                    select: "Select the date",
                    required: "Date is required",
                },
                username: {
                    title: "Username",
                    placeholder: "Enter the username",
                    select: "Select the username",
                    required: "Username is required",
                },
                name: {
                    title: "Name",
                    placeholder: "Enter the name",
                    select: "Select the name",
                    required: "Name is required",
                },
                code: {
                    title: "Code",
                    placeholder: "Enter the code",
                    select: "Select the code",
                    required: "Code is required",
                },
                serialNumber: {
                    title: "Serial number",
                    placeholder: "Enter the serial number",
                    select: "Select the serial number",
                    required: "Serial number is required",
                },
                password: {
                    title: "Password",
                    placeholder: "Enter the password",
                    required: "Password is required",
                },
                status: {
                    title: "Status",
                    placeholder: "Enter the status",
                    select: "Select the status",
                    required: "Status is required",
                },
                location: {
                    title: "Location",
                    placeholder: "Enter the location",
                    select: "Select the location",
                    required: "Location is required",
                },
                distance: {
                    title: "Distance",
                    placeholder: "Enter the distance in meters",
                    select: "Select the distance",
                    required: "Distance is required",
                },
                direction: {
                    title: "Direction",
                    placeholder: "Enter the direction",
                    select: "Select the direction",
                    required: "Direction is required",
                },
                category: {
                    title: "Categories",
                    placeholder: "Enter the category",
                    select: "Select the category",
                    required: "Category is required",
                    amount: {
                        placeholder:"Enter the quantity",
                        required:"Quantity is required",
                    },
                },
                father: {
                    title: "Father",
                    placeholder: "Enter the father",
                    select: "Select the father",
                    required: "Father is required",
                },
                rfid: {
                    title: "RFID",
                    placeholder: "Enter the RFID",
                    select: "Select the RFID",
                    required: "RFID is required",
                },
                type: {
                    title: "Type",
                    placeholder: "Enter the type",
                    select: "Select the type",
                    required: "Type is required",
                },
                ip: {
                    title: "Ip",
                    placeholder: "Enter the ip",
                    select: "Select the ip",
                    required: "Ip is required",
                },
                mac: {
                    title: "MAC",
                    placeholder: "Enter the MAC",
                    select: "Select the MAC",
                    required: "MAC is required",
                },
                host: {
                    title: "Host",
                    placeholder: "Enter the host",
                    select: "Select the Host",
                    required: "Host is required",
                },
                protocol: {
                    title: "Protocol",
                    placeholder: "Enter the Protocol",
                    select: "Select the Protocol",
                    required: "Protocol is required",
                },
                port: {
                    title: "Port",
                    placeholder: "Enter the Port",
                    select: "Select the Port",
                    required: "Port is required",
                },
                token: {
                    title: "Token",
                    placeholder: "Enter the Token",
                    select: "Select the Token",
                    required: "Token is required",
                },
                url: {
                    title: "Url",
                    placeholder: "Enter the url",
                    select: "Select the url",
                    required: "Url is required",
                },
                path: {
                    title: "Path",
                    placeholder: "Enter the path",
                    select: "Select the path",
                    required: "Path is required",
                },
                period: {
                    title: "Period",
                    placeholder: "Enter the period",
                    select: "Select the period",
                    required: "Period is obrigatório",
                  },
            },
            menu: {
                dashboard: "Dashboard",
                panel: "Panel",
                camera: "Sensors",
                survey: "Evaluations",
                category: "Categories",
                rfid: "RFIDs",
                locations: "Locations",
                maintenance: "Maintenances",
                device: "Devices",
                statistics: "Statistics",
                hosthttp: "Alarm Server",
                visits: "Visits",
                alert: "Alerts",
            },
            dashboard: {
                chart_users: "Chart showing number of users x rating",
                chart_problems: "Complaint rate by type of breakdown/problem",
                users: "Users",
                surveys: "Evaluation",
                occupation_time: "Occupation by hour",
                maintenance: "Real-time maintenance",
                maintenance_category: "Maintenance by category",
                maintenance_location: "Maintenance by location",
                maintenance_rfid: "Maintenance by rfid",
                entrys: "Entrys",
                exits: "Exits",
                men: "Men",
                woman: "Women",
                all: "All",
                last_month: "Last 30 days",
                seven_day: "Last 7 days",
                yesterday: "Yesterday",
                today: "Today",
                avg: "Avg",
                notification: "Notifications",
                top4: "Top 4 - Most rated toilets",
                avgwc: "Average of toilets *",
                loadmore: "loading more",
                minute: "{{minute}} minutes ago",
            },
            action: {
                close: "Close",
                view: "View",
                title: "Actions",
                new: "New {{name}}",
                save: "Save",
                edit: "Edit {{name}}",
                delete: "Remove ",
                clone: "Clone {{name}}",
                copy: "Copy {{name}}",
                paste: "Past {{name}}",
                logout: "Logout",
                search: "Search",
                clear: "Clear",
                cancel: "Cancel",
                ok: "Ok",
                yes: "Yes",
                no: "No",
                xlsx: "Export to Xlsx",
                csv: "Export to Csv",
                export: "Export",
                download: "Download report",
                go: "Go to {{name}}",
            },
            message: {
                confirm: "Confirmation",
                confirmContentDelete: "Do you really want to remove {{name}}?",
                confirmContentAuth: "Do you really want to authorize {{name}}?",
                confirmContentBlock: "Do you really want to block {{name}}?",
                succes: "Successfully performed operation",
                save: "{{name}} saved successfully",
                update: "{{name}} updated successfully",
                remove: "{{name}} deleted successfully"
            },
            error: {
                title: "Error",
                operation: "Error performing this operation",
                down: "no connection to the system",
                notauthorized: "You do not have permission to access this page.",
                tryagain: "Try again",
                env: "No environment"
            },
            device: {
                title: "Device",
                key: {
                    title: "Public key",
                    placeholder: "Enter the public key",
                    select: "Select the public key",
                    required: "public key is required",
                },
                deviceId: {
                    title: "Device id",
                    placeholder: "Enter device id",
                    select: "Select the device id",
                    required: "Device id is required",
                },
                qrcode: "Read QR code",
                android: "Android",
                brand: {
                    title: "Brand",
                    placeholder: "Enter the Brand",
                    select: "Select the Brand",
                    required: "Brand  is required",
                },
                authorize: "Authorize",
                block: "Block",
                blocked: "Blocked",
                authorized: "Authorized",
                qrcodeinfo: "In the app go to: Application configuration>security and position the qrcode in front of the camera",
            },
            camera: {
                title: "Sensor",
                select: "Select the sensor",
                model: {
                    title: "Model",
                    placeholder: "Enter the model",
                    select: "Select the model",
                    required: "Model is required",
                },
                config: "Config",
            },
            location: {
                generalData: "General data",
                children: "Children",
                feminine: "Feminine",
                masculine: "Masculine",
                deficient: "Deficient",
                nursery: "Nursery",
                block: "Block",
                floor: "Floor",
                bathroom: "Toilet",
                title: "Location",
                titles: "Locations",
                empty: "Select a location or click New location to get started",
                father: "Father",
                fatherMe: "Can't be his own father",
                suggestionMe: "Cannot suggest this same location",
                up: "Up",
                down: "Down",
                left: "Left",
                right: "Right",
                suggested: "Suggested location",
                capacity: {
                    title: "Capacity",
                    placeholder: "Enter the capacity",
                    select: "Select the capacity",
                    required: "Capacity is required",
                },
                audience: {
                    title: "Audience",
                    placeholder: "Enter the audience",
                    select: "Select the audience",
                    required: "Audience is required",
                }
            },
            rfid: {
                title: "RFID",
                father: "Father",
            },
            maintenance: {
                maintenance: "Equipment maintenance",
                cleaning: "Cleaning and refueling",
                title: "Maintenance",
                type:{
                    title:"Type",
                    placeholder:"Enter the type maintenance type",
                    select:"Select the type",
                    required:"Type is required",
                },
                histories: {
                    title: "Histories",
                    placeholder: "Enter the histories",
                    select: "Select the histories",
                    required: "Histories is required",
                },
                start: {
                    title: "Start maintenance",
                    placeholder: "Enter the start maintenance",
                    select: "Select the start maintenance",
                    required: "Start maintenance is required",
                },
                end: {
                    title: "End maintenance",
                    placeholder: "Enter the end maintenance",
                    select: "Select the end maintenance",
                    required: "End maintenance is required",
                },
                status: {
                    started: "Started",
                    canceled: "Canceled",
                    finished: "Finished",
                    pending: "pending"
                }
            },
            survey: {
                title: "Evaluation",
                titles: "Evaluations",
                grades: {
                    title: "Grades",
                    placeholder: "Enter the grade",
                    select: "Select the grade",
                    required: "Grade is required",
                    bad: "Bad",
                    regular: "Regular",
                    good: "Good",
                    excellent: "Excellent",
                }
            },
            event: {
                entry: "Entry",
                exit: "Exit",
                adjust: "Adjust",
            },
            category: {
                notify: {
                    title: "Notify after how many users",
                    placeholder: "Enter the notify",
                    select: "Select the notify",
                    required: "Notify is required",
                },
                nameTranslated: "Name second language"
            },
            report: {
                percentualAvaliation: "% Ava.",
                avaliation: "Reviews",
                visits: "Visits",
              },
              aggregation: {
                title: "Aggregation",
                select: "Select an aggregation",
                HOUR: "Hour",
                DAY: "Day",
                MONTH: "Month",
                WEEK: "Week",
                YEAR: "Year",
              },
        }
    }
}
export { languages }
