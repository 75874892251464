import { Col, DatePicker, Modal, Select } from "antd"
import dayjs from "dayjs"
import React, { useContext, useEffect } from "react"
import calendar from "../../../assets/icons/actions/calendar.svg"
import { MenuContext } from "../../../context/menu"
import { Translate } from "../../../translate"
import { datetimeFormat, toDateLocalNow } from "../../../util/time"

import Title from "antd/es/typography/Title"

export const defaultStart = MenuContext.defaultStart
export const defaultStop = MenuContext.defaultStop

const MenuDashboard = (props) => {

    const { valueSelected, setValueSelected, data, setData } = useContext(MenuContext)
    const { RangePicker } = DatePicker
    let { onClicked } = props

    const onSelect = (value) => {
        setData(onClickHandler(value))
        setValueSelected(value)
    }

    function onClickHandler(_value) {
        let newData = {}
        switch (_value) {
            case "now":
                newData.start = toDateLocalNow().hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().hour(23).minute(59).second(59)
                return newData
            case "yesterday":
                newData.start = toDateLocalNow().subtract(1, 'day').hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().subtract(1, 'day').hour(23).minute(59).second(59)
                return newData
            case "7days":
                newData.start = toDateLocalNow().subtract(7, "day").hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().hour(23).minute(59).second(59)
                return newData
            case "lastlmonth":
                newData.start = toDateLocalNow().subtract(1, "month").hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().hour(23).minute(59).second(59)
                return newData
            case "all":
                newData.start = toDateLocalNow().subtract(10, "year").hour(0).minute(0).second(0)
                newData.end = toDateLocalNow().hour(23).minute(59).second(59)
                return newData
            default:
                setData(newData)
                setValueSelected(_value)
        }
    }

    let date = [
        {
            label: <Title level={5} type="secondary">{Translate.t("dashboard.today")}</Title>,
            value: "now",
            key: "now"
        },
        {
            label: <Title level={5} type="secondary">{Translate.t("dashboard.yesterday")}</Title>,
            value: "yesterday",
            key: "yesterday"
        },
        {
            label: <Title level={5} type="secondary"> {Translate.t("dashboard.seven_day")}</Title>,
            value: "7days",
            key: "7days"
        },
        {
            label: <Title level={5} type="secondary">{Translate.t("dashboard.last_month")}</Title>,
            value: "lastlmonth",
            key: "lastlmonth"
        },
        {
            label: <Title level={5} type="secondary">  {Translate.t("dashboard.all")}</Title>,
            value: "all",
            key: "all"
        }]


    useEffect(() => {
        if (onClicked) {
            onClicked(data)
        }
    }, [data])


    const selectDate = () => {
        Modal.warn({
            title: Translate.t("common.date.select"),
            content: (
                <div>
                    <RangePicker
                        onChange={(newValue) => {
                            setData({
                                start: newValue[0],
                                end: newValue[1]
                            })
                        }}
                        showTime={true}
                        format={datetimeFormat()} />
                </div>
            ),
            onOk() {

            },
        })
    }

    return (
        <>
            <Col id="menu" className="dashboard-select-container"  >
                    <Select className="dashboard-select" style={{ width: "150px",marginRight:"10px" }}
                        defaultValue="now"
                        value={valueSelected==='selection'?"Data customizada":valueSelected}
                        placeholder={Translate.t("common.rfid.select")}
                        options={date}
                        onSelect={onSelect}
                    />
                    <a
                        className={valueSelected === "selection" ? "selected" : ""}
                        onClick={() => {
                            onClickHandler("selection")
                            selectDate()
                        }}
                    >

                        {valueSelected === "selection" && data.start && data.end ?
                            <span style={{ fontSize: 14 }} className="no-mobile">  {dayjs(data?.start).format(datetimeFormat())}-{dayjs(data?.end).format(datetimeFormat())}</span>
                            : <span className="secondary-text-color" style={{ fontSize: 16 }}>{Translate.t("common.date.select")}</span>
                        }
                        <img style={{ marginLeft: "3px" }} src={calendar} alt=""></img>
                    </a>
          
            </Col>
        </>
    )
}

export default MenuDashboard
