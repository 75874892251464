import { DownOutlined } from "@ant-design/icons";
import { Checkbox, Col, Popover, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Translate } from "../../../translate";
import { getValueStorage, setValueStorage } from "../../../util/localStorange";

const List = ({ data, pagination, loading, search, categories }) => {
  const [columnsVisives, setColumnsVisives] = useState([]);
  const [columns, setColumns] = useState([]);
  const handleCategories = (cat, categories) => {
    let item =
      categories &&
      categories.length > 0 &&
      categories.find((i) => i.name === cat);
    return item ? item.value : 0;
  };

  const handleColumns = () => {
    setColumns([
      {
        index: 0,
        title: Translate.t("common.period.title"),
        dataIndex: "range",
        key: "range",
        width: 250,
      },
      {
        index: 1,
        title: Translate.t("aggregation.title"),
        dataIndex: "date",
        key: "date",
        width: 150,
      },
      {
        index: 2,
        title: Translate.t("location.title"),
        dataIndex: "location",
        key: "location",
        width: 450,
        render: (text, record) => record.location + " - " + record.father,
      },
      {
        index: 3,
        title: Translate.t("menu.alert"),
        dataIndex: "value",
        key: "value",
        width: 200,
      },
      ...categories.map((category, index) => ({
        index: (index = 4),
        title: category.label,
        dataIndex: category.label,
        key: category.label,
        render: (text, record) =>
          handleCategories(category.label, record.categories),
        width: 200,
      })),
      ,
    ]);
  };

  // const handleHiddenColumns = (collIndex) => {
  //   if (columnsVisives.length > 0)
  //     return columnsVisives.find(
  //       (i, index) => index === collIndex && i.visible
  //     );
  //   return true;
  // };

  // const onChangeHiddenColumns = (index, checked) => {
  //   let _columns = (columnsVisives || []).map((i, _index) => ({
  //     ...i,
  //     visible: _index === index ? checked : i.visible,
  //   }));
  //   setValueStorage("columnsAlerts", JSON.stringify(_columns));
  //   setColumnsVisives(_columns);
  // };

  // useEffect(() => {
  //   let value = getValueStorage("columnsAlerts");
  //   if (value) setColumnsVisives(JSON.parse(value));
  //   else {
  //     let _columns = [
  //       { label: Translate.t("common.period.title"), key: "0", visible: true },
  //       { label: Translate.t("aggregation.title"), key: "1", visible: true },
  //       { label: Translate.t("location.title"), key: "2", visible: true },
  //       { label: Translate.t("menu.alert"), key: "3", visible: true },
  //     ];
  //     setValueStorage("columnsAlerts", JSON.stringify(_columns));
  //     setColumnsVisives(_columns);
  //   }
  // }, []);

  useEffect(() => {
    handleColumns();
  }, [categories]);

  return (
    <>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} className="card_default padding-list">
          <Row className="space-botton padding">
            <Col xs={12}>
              <span className="text-header primary-text-color">
                {Translate.t("title.search_result")}
              </span>
            </Col>
            <Col xs={12} className="alng-rigth">
              {columnsVisives.length > 0 && (
                <Popover
                  content={(columnsVisives || []).map((i, index) => (
                    <p value={i.label}>
                      <Checkbox
                        checked={i.visible}
                        onClick={(e) => {
                          // onChangeHiddenColumns(index, e.target.checked);
                          e.stopPropagation();
                        }}
                      >
                        {" "}
                        <span className="secondary-text-color">{i.label}</span>
                      </Checkbox>
                    </p>
                  ))}
                  placement="bottom"
                  title={null}
                  trigger="hover"
                >
                  <span className="secondary-text-color">
                    {" "}
                    {Translate.t("common.selectInfo")}
                  </span>
                  <DownOutlined />
                </Popover>
              )}
            </Col>
          </Row>
          <Table
            scroll={{ y: 99999, x: 400 }}
            dataSource={data}
            columns={columns}
            // columns={columns.filter((column) =>
            //   handleHiddenColumns(column.index)
            // )}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default List;
