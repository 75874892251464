import { BellOutlined } from "@ant-design/icons";
import { Badge, Drawer, Space, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import api from "../../services/api";
import { Translate } from "../../translate";
import { toDateLocalNow } from "../../util/time";
import Notification from "../dashboard/Notification";
import { messageError } from "../../util/messages";

const Bell = () => {
  const { notification, setNotification, environment } =
    useContext(AuthContext);
  const [dot, setDot] = useState(false);
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [last, setLast] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let { data } = await api.get("/survey/survey/page/notification", {
          params: {
            rowPerPage: 10,
          },
        });
        setData(data);
        setIsLoading(false);
      } catch (error) {
        messageError(error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (data.empty === false) {
        if (last === undefined || last.key !== data.content[0].key)
          setDot(true);
        setLast(data.content[0]);
        setNotification(data.content);
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    setStart(toDateLocalNow().hour(0).minute(0).second(0));
  }, [environment]);

  return (
    <>
      <Space
        className="badge-header"
        onClick={() => {
          setDot(false);
          setOpen(true);
        }}
      >
        <Badge key="green" color="green" dot={dot}>
          <BellOutlined />
        </Badge>
      </Space>
      {open && (
        <Drawer
          title={
            <p
              className="header-drawer text-header primary-text-color"
              style={{ textAlign: "center" }}
            >
              {Translate.t("dashboard.notification")}
            </p>
          }
          placement="right"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Notification height={300} />
        </Drawer>
      )}
    </>
  );
};

export default Bell;
