
import pt_BR from "antd/lib/locale/pt_BR";
import pt_PT from "antd/lib/locale/pt_PT";
import en_US from "antd/lib/locale/en_US";

const handleLocales = (langange) => {
    switch (langange) {
        case "en":
            return en_US
        case "pt-BR":
            return pt_BR
        default:
            return pt_PT
    }
}

export default handleLocales