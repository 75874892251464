import { Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { Translate } from "../../translate";
import data from "./data";

const _Menu = () => {
  const { hasAccess, environment } = useContext(AuthContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(
      data
        .filter((_menu) => hasAccess(_menu.resources))
        .map((_menu) => generateItems(_menu))
    );
  }, [environment]);

  function generateItems(_menu) {
    return _menu.submenus
      ? {
          title: Translate.t(_menu.title),
          label: Translate.t(_menu.title),
          key: _menu.id ,
          icon: _menu.icon ? (
            <img
              src={require(`../../assets/icons/menu/${_menu.icon}`)}
              alt=""
            />
          ) : (
            <></>
          ),
          children: _menu.submenus
            .filter((_sub) => hasAccess(_sub.resources))
            .map((_submenu) => generateItems(_submenu)),
        }
      : {
          title: Translate.t(_menu.title),
          label: <Link to={_menu.url}>{Translate.t(_menu.title)}</Link>,
          key: _menu.id,
          icon: _menu.icon ? (
            <img
              src={require(`../../assets/icons/menu/${_menu.icon}`)}
              alt=""
            />
          ) : (
            <></>
          ),
        };
  }

  return (
    <>
      <Menu
        className="menu-content"
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
      ></Menu>
    </>
  );
};

export default _Menu;
