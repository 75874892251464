import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import AvgWc from "../../components/dashboard/AvgWc";
import Problems from "../../components/dashboard/chart/ChartProblems";
import Users from "../../components/dashboard/chart/ChartUsers";
import MenuDashboard from "../../components/dashboard/menu";
import Notification from "../../components/dashboard/Notification";
import Top4 from "../../components/dashboard/Top4";
import { MenuContext } from "../../context/menu";
import { Translate } from "../../translate";

const Dashboard = () => {
  const { data } = useContext(MenuContext);
  const [start, setStart] = useState(data.start);
  const [end, setEnd] = useState(data.stop);

  const onClickedMenu = (data) => {
    let { start, end } = data;
    setStart(start);
    setEnd(end);
  };

  return (
    <Row gutter={8}>
      <Col xs={24} sm={24} md={16} lg={18}>
        <Row gutter={16}>
          <Col xs={24} className="space-botton">
            <span className="text-title primary-text-color">
              {Translate.t("menu.dashboard")}
            </span>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="space-botton">
            <span className="text-sub secondary-text-color">
              {Translate.t("title.info.dashboard")}
            </span>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            className="alng-rigth space-botton"
          >
            <MenuDashboard onClicked={onClickedMenu} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} className="space-botton">
            <Users start={start} end={end} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} className="space-botton">
            <Top4 start={start} end={end} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} className="space-botton">
            <Problems start={start} end={end} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} className="space-botton">
            <AvgWc start={start} end={end} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={8} lg={6}>
        <Row style={{ marginLeft: "7px" }}>
          <Col xs={24}>
            <Row>
              <Col
                className="card_default notification"
                style={{ maxHeight: "56.4em" }}
              >
                <p
                  className="text-header primary-text-color"
                  style={{ textAlign: "center" }}
                >
                  {Translate.t("dashboard.notification")}
                </p>
                <div style={{ marginTop: "20px" }}>
                  <Notification height={420} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
