import { Col, Row } from "antd"
import React, { useContext, useEffect, useState } from "react"
import Tree from "../../components/locations/List"
import { AuthContext } from "../../context/auth"
import api from "../../services/api"
import { Translate } from "../../translate"
import { messageError } from "../../util/messages"

const Locations = () => {

    const { environment } = useContext(AuthContext)

    const [locations, setLocations] = useState([])
    const [types, setTypes] = useState([])
    const [audiences, setAudiences] = useState([])
    const [directions, setDirections] = useState([])

    const fetchLocations = () => {
        api.get("/maintenance/location/tree/")
            .then((response) => { setLocations(response.data) })
            .catch((error) => messageError(error))
    }

    const fetchTypes = () => {
        api.get("/maintenance/location/types/")
            .then((response) => { setTypes(response.data) })
            .catch((error) => messageError(error))
    }

    const fetchAudience = () => {
        api.get("/maintenance/location/audiences")
            .then((response) => { setAudiences(response.data) })
            .catch((error) => messageError(error))
    }

    const fetchDirections = () => {
        api.get("/maintenance/location/directions")
            .then((response) => { setDirections(response.data) })
            .catch((error) => messageError(error))
    }

    useEffect(() => {
        fetchLocations()
        fetchTypes()
        fetchAudience()
        fetchDirections()
    }, [environment])

    return (
        <>
            <Row>
                <Col xs={24}>
                    <span className="text-title primary-text-color">{Translate.t("menu.locations")}</span>
                </Col>
                <Col xs={24} style={{marginTop:"10px"}}> 
                    <span className="text-sub secondary-text-color">{Translate.t("title.info.local")}</span>
                </Col>
            </Row>
            <Tree
                locations={locations}
                fetchLocations={fetchLocations}
                types={types}
                audiences={audiences}
                directions={directions}
            />
        </>
    )
}

export default Locations 