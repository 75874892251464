import Translate from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { languages } from "./languages"

Translate.use(LanguageDetector).init({
    debugger: false,
    defaultNs: ['translations'],
    fallbackLng: 'pt',
    ns: ['translations'],
    resources: languages
})

export { Translate }
