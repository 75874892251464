import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Space, Switch, TreeSelect } from "antd"
import React, { useEffect, useState } from "react"
import api from "../../services/api"
import { Translate } from "../../translate"
import { optionsEntity, optionsEnum } from "../../util/handleItens"
import { messageError, notification } from "../../util/messages"
import { datetimeFormat, toDateUtc } from "../../util/time"

const Register = ({ initialValues = {}, open, setOpen, search, categories, locations, status, rfids, types }) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [statusAll, setStatusAll] = useState([])
    const [value, setValue] = useState()
    const [checkedCategories, setCheckedCategories] = useState([])

    const onFinish = async (values) => {
        try {
            setLoading(true)
            values.start = toDateUtc(values.start)
            if (!values.histories || values.histories.length === 0) {
                form.setFields([
                    {
                        name: 'histories',
                        errors: [Translate.t("maintenance.histories.required")],
                    },
                ])
                return
            }
            values.histories = (values.histories || []).map(i => ({ ...i, rfid: values.rfid.id, timestamp: toDateUtc(i.timestamp) }))
            if (values.id) {
                await api.put(`/maintenance/maintenance/${values.id}`, values)
                notification("success", "update", Translate.t("maintenance.title"))
            }
            else {
                await api.post(`/maintenance/maintenance/`, values)
                notification("success", "save", Translate.t("maintenance.title"))
            }
            form.resetFields()
            setOpen(false)
            search({})
        }
        catch (error) {
            messageError(error)
        }
        finally {
            setLoading(false)
        }

    }

    const categoryChange = newValue => {
        setCheckedCategories([...checkedCategories.filter((c) => c != value), newValue]);
        setValue(newValue);
    }

    const onOk = () => {
        form.submit()
    }

    const onCancel = () => {
        setOpen(false)
        form.resetFields()
    }

    const hanldeDisabled = () => {
        let histories = form.getFieldValue("histories")
        let select = (histories || []).map(i => i.status)
        statusAll.forEach(element => {
            if (select.includes(element.value))
                element.disabled = true
            else
                element.disabled = false
        })
        setStatusAll(statusAll)
    }

    useEffect(() => {
        setStatusAll((optionsEnum(status) || []).map(i => ({ ...i, label: Translate.t(`maintenance.status.${i.label}`) })))
        if (initialValues != null && initialValues.categories != null)
            setCheckedCategories(initialValues.categories.map(c => c.id))
    }, [initialValues])

    return (
        <Modal
            title={<span>{initialValues.id ? Translate.t("action.edit", { name: Translate.t("maintenance.title") }) : Translate.t("action.new", { name: Translate.t("maintenance.title") })}</span>}
            open={open}
            onCancel={() => onCancel()}
            maskClosable={false}
            footer={null}>
            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues} >
                <Row className="default-padding">
                    <Form.Item name="id" >
                        <input type="hidden" />
                    </Form.Item>
                    <Form.Item name="deleted" >
                        <input type="hidden" />
                    </Form.Item>
                    <Form.Item name="status" >
                        <input type="hidden" />
                    </Form.Item>
                    <Form.Item name="end" >
                        <input type="hidden" />
                    </Form.Item>
                    <Col xs={24}>
                        <Form.Item name="type" label={Translate.t("common.type.title")}
                            rules={[{ required: true, message: Translate.t("common.type.required") }]}
                        >
                            <Select
                                allowClear
                                placeholder={Translate.t("common.type.select")}
                                options={(optionsEnum(types) || []).map(i => ({ ...i, label: Translate.t(`maintenance.${i.value}`) }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name={["rfid", "id"]} label={Translate.t("common.rfid.title")}
                            rules={[{ required: true, message: Translate.t("common.rfid.required") }]}
                        >
                            <Select
                                allowClear
                                placeholder={Translate.t("common.rfid.select")}
                                options={optionsEntity(rfids)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="start" label={Translate.t("maintenance.start.title")}
                            rules={[{ required: true, message: Translate.t("maintenance.start.required") }]}
                        >
                            <DatePicker style={{ width: "100%" }} showTime format={datetimeFormat()} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name={["location", "id"]} label={Translate.t("common.location.title")}
                            rules={[{ required: true, message: Translate.t("common.location.required") }]}
                        >
                            <TreeSelect
                                allowClear
                                showSearch
                                placeholder={Translate.t("common.location.select")}
                                treeDefaultExpandAll
                                treeData={locations}
                                filterTreeNode={(search, item) => {
                                    return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <div className="primary-text-color" style={{ marginBottom: "10px" }}>{Translate.t("common.category.title")}</div>
                        <Form.List name="categories" label={Translate.t("common.category.title")}
                            rules={[{ required: true, message: Translate.t("common.category.required") }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: "flex",
                                                marginBottom: 8,
                                            }}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                style={{ minWidth: "15vw" }}
                                                {...restField}
                                                name={[name, 'id']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: Translate.t("common.category.required"),
                                                    },
                                                ]}
                                            >
                                                <Select value={value} onChange={categoryChange} title={Translate.t("common.category.placeholder")} placeholder={Translate.t("common.category.placeholder")}>
                                                    {categories.map((category) => <Select.Option disabled={checkedCategories.some(c => c === category.value)} key={category.key} value={category.value}>{category.title}</Select.Option>)}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item

                                                style={{ minWidth: "5vw" }}
                                                {...restField}
                                                name={[name, 'amount']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: Translate.t("common.category.amount.required"),
                                                    },
                                                ]}
                                            >
                                                <Input min={1} max={999999} placeholder={Translate.t("common.category.amount.placeholder")} title={Translate.t("common.category.amount.placeholder")} />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            {Translate.t("common.add", { name: Translate.t("common.category.title") })}
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>

                    <> <Divider />
                        <Col xs={24}>
                            <div className="primary-text-color" style={{ marginBottom: "10px" }}>{Translate.t("maintenance.histories.title")}</div>
                            <Form.List
                                name="histories"
                                rules={[
                                    {
                                        validator: async (_, values) => {
                                            if (!values || values.length < 0) {
                                                return Promise.reject(new Error(Translate.t("maintenance.histories.required")))
                                            }
                                        },
                                    }]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                required={true}
                                                key={field.key}
                                            >
                                                <input type="hidden" name="pinId" />
                                                <Row gutter={16}>
                                                    <Col xs={24} md={11}>

                                                        <Form.Item
                                                            {...field}
                                                            name={[index, "status"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: Translate.t("common.status.required"),
                                                                },
                                                            ]}
                                                            noStyle
                                                        >
                                                            <Select
                                                                allowClear
                                                                onSelect={(i) => hanldeDisabled()}
                                                                placeholder={Translate.t("common.status.select")}
                                                                options={statusAll}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={11}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[index, "timestamp"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: Translate.t("common.date.required"),
                                                                },
                                                            ]}
                                                            noStyle
                                                        >
                                                            <DatePicker style={{ width: "100%" }} showTime format={datetimeFormat()} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={2} style={{ textAlign: "center", marginTop: '10px' }}>
                                                        <MinusCircleOutlined title={Translate.t("action.delete")}
                                                            className="dynamic-delete-button"
                                                            onClick={() => {
                                                                remove(field.name)
                                                                hanldeDisabled()
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button title={Translate.t("common.add", { name: Translate.t("maintenance.histories.title") })}
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{ width: '60%' }}
                                                icon={<PlusOutlined />}
                                            >
                                                {Translate.t("common.add", { name: Translate.t("maintenance.histories.title") })}
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </>
                    <Col xs={12}>
                        <Form.Item name="enabled" valuePropName="checked" label={Translate.t("common.enabled")}
                        >
                            <Switch defaultChecked />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <div className="btn-right">
                            <Button key="back" onClick={() => onCancel()} style={{ marginRight: "10px" }}>
                                {Translate.t("action.cancel")}
                            </Button>
                            <Button key="submit" type="primary" loading={loading} onClick={() => onOk()}>
                                {Translate.t("action.ok")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default Register