import { Button, Col, Form, Input, Modal, Row, Select, Switch, TreeSelect } from "antd"
import React, { useState } from "react"
import api from "../../services/api"
import { Translate } from "../../translate"
import { optionsEnum } from "../../util/handleItens"
import { messageError, notification } from "../../util/messages"

const Register = ({ initialValues = {}, open, setOpen, search, locations, types }) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const onFinish = async (values) => {
        try {
            setLoading(true)
            values.locations = values.locations.map(i => ({ id: i }))
            if (values.id) {
                await api.put(`/maintenance/pin/${values.id}`, values)
                notification("success", "update", Translate.t("rfid.title"))
            }
            else {
                await api.post(`/maintenance/pin/`, values)
                notification("success", "save", Translate.t("rfid.title"))
            }
            form.resetFields()
            setOpen(false)
            search({})
        }
        catch (error) {
            messageError(error)
        }
        finally {
            setLoading(false)
        }
    }

    const onOk = () => {
        form.submit()
    }

    const onCancel = () => {
        setOpen(false)
        form.resetFields()
    }

    return (
        <Modal
            title={<span>{initialValues.id ? Translate.t("action.edit", { name: Translate.t("rfid.title") }) : Translate.t("action.new", { name: Translate.t("rfid.title") })}</span>}
            open={open}
            maskClosable={false}
            onCancel={() => onCancel()}
            footer={null}>
            <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues} >
                <Row className="default-padding">
                    <Form.Item name="id" >
                        <input type="hidden" />
                    </Form.Item>
                    <Form.Item name="deleted" >
                        <input type="hidden" />
                    </Form.Item>
                    <Col xs={24}>
                        <Form.Item name="name" label={Translate.t("common.name.title")}
                            rules={[{ required: true, message: Translate.t("common.name.required") }]}
                        >
                            <Input placeholder={Translate.t("common.name.placeholder")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="code" label={Translate.t("common.code.title")}
                            rules={[{ required: true, message: Translate.t("common.code.required") }]}
                        >
                            <Input placeholder={Translate.t("common.code.placeholder")} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="type" label={Translate.t("common.type.title")}
                            rules={[{ required: true, message: Translate.t("common.type.required") }]}
                        >
                            <Select
                                allowClear
                                placeholder={Translate.t("common.type.select")}
                                options={(optionsEnum(types) || []).map(i => ({ ...i, label: Translate.t(`maintenance.${i.value}`) }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="locations" label={Translate.t("location.titles")}
                            rules={[{ required: true, message: Translate.t("common.location.required") }]}
                        >
                            <TreeSelect
                                multiple
                                allowClear
                                showSearch
                                placeholder={Translate.t("common.location.select")}
                                treeDefaultExpandAll
                                treeData={locations}
                                filterTreeNode={(search, item) => {
                                    return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item name="enabled" valuePropName="checked" label={Translate.t("common.enabled")}
                        >
                            <Switch defaultChecked checkedChildren={Translate.t("action.yes")} unCheckedChildren={Translate.t("action.no")} />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <div className="btn-right">
                            <Button key="back" onClick={() => onCancel()} style={{ marginRight: "10px" }}>
                                {Translate.t("action.cancel")}
                            </Button>
                            <Button key="submit" type="primary" loading={loading} onClick={() => onOk()}>
                                {Translate.t("action.ok")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default Register