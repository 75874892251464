import { useKeycloak } from "@react-keycloak/web"
import React, { useContext } from "react"

import {
  BrowserRouter, Route,
  Routes
} from "react-router-dom"
import Loading from "./components/loading"
import { AuthContext, AuthProvider } from "./context/auth"
import { MenuProvider } from "./context/menu"
import Templeate from "./template"
import { Translate } from "./translate"

const AppRoutes = () => {

  const { keycloak } = useKeycloak()

  const Private = ({ children }) => {
    const { hasAccess, loading, authenticated } = useContext(AuthContext)
    if (loading) return <Loading />
    if (!authenticated) {
      return keycloak.login()
    } else if (!hasAccess('bathroom')) {
      return <div style={{ textAlign: "center", marginTop: "45vh" }}><h2>{Translate.t("error.notauthorized")} <a onClick={() => window.location.reload(true)}>{Translate.t("error.tryagain")}</a></h2></div>
    }
    return children;
  }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <Routes>
          <Route
            path="*"
            element={
              <Private>
                <MenuProvider>
                  <Templeate />
                </MenuProvider>
              </Private>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default AppRoutes;

