import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import Camera from "../../pages/camera";
import Dashboard from "../../pages/dashboard";
import Device from "../../pages/device";
import Host from "../../pages/host";
import Locations from "../../pages/locations";
import Maintenance from "../../pages/maintenance";
import MaintenanceCategory from "../../pages/maintenance_category";
import Alert from "../../pages/reports/alert";
import MaintenanceReport from "../../pages/reports/maintenance";
import Survey from "../../pages/reports/survey";
import Visits from "../../pages/reports/visits";
import Rfid from "../../pages/rfid";

const Content = () => {
  const { hasAccess } = useContext(AuthContext);

  return (
    <Routes>
      <Route exact path={"/"} element={<Dashboard />} />

      {(function (route) {
        if (hasAccess("bathroom_control"))
          return <Route exact path={route} element={<Camera />} />;
      })("/camera")}

      {(function (route) {
        if (hasAccess("bathroom_survey"))
          return <Route exact path={route} element={<Alert />} />;
      })("/reports/alerts")}

      {(function (route) {
        if (hasAccess("bathroom_maintenance"))
          return <Route exact path={route} element={<Locations />} />;
      })("/locations")}

      {(function (route) {
        if (hasAccess("bathroom_maintenance"))
          return <Route exact path={route} element={<Rfid />} />;
      })("/rfid")}

      {(function (route) {
        if (hasAccess("bathroom_maintenance"))
          return <Route exact path={route} element={<Maintenance />} />;
      })("/maintenance")}

      {(function (route) {
        if (hasAccess("bathroom_maintenance"))
          return <Route exact path={route} element={<MaintenanceCategory />} />;
      })("/maintenance/category")}

      {(function (route) {
        if (hasAccess("bathroom_control"))
          return <Route exact path={route} element={<Device />} />;
      })("/device")}

      {(function (route) {
        if (hasAccess("bathroom_control"))
          return <Route exact path={route} element={<Visits />} />;
      })("/reports/visits")}

      {(function (route) {
        if (hasAccess("bathroom_host"))
          return <Route exact path={route} element={<Host />} />;
      })("/host")}

      {(function (route) {
        if (hasAccess("bathroom_survey") || hasAccess("bathroom_control"))
          return <Route exact path={route} element={<Survey />} />;
      })("/reports/survey")}

      {(function (route) {
        if (hasAccess("bathroom_maintenance"))
          return <Route exact path={route} element={<MaintenanceReport />} />;
      })("/reports/maintenance")}

      <Route
        exact
        path="*"
        element={
          <div
            style={{
              textAlign: "center",
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            <h1>Erro 404 - Não há nada aqui</h1>
          </div>
        }
      />
    </Routes>
  );
};

export default Content;
