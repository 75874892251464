import { DeleteOutlined, DownOutlined, ExclamationCircleFilled, LockOutlined, UnlockOutlined } from "@ant-design/icons"
import { Button, Checkbox, Col, Modal, Popover, Row, Table } from "antd"

import React, { useEffect, useRef, useState } from "react"
import { QrReader } from "react-qr-reader"
import api from "../../services/api"
import { Translate } from "../../translate"
import { getValueStorage, setValueStorage } from "../../util/localStorange"
import { messageError, messageWarn, notification } from "../../util/messages"
import { datetime } from "../../util/time"
import { isBrowser } from 'react-device-detect';


const List = ({ data, pagination, loading, search, item = {}, setItem, open, setOpen }) => {

    const [columnsVisives, setColumnsVisives] = useState([])
    const [contextMenuPosition, setContextMenuPosition] = useState(null)
    const [contextMenuVisible, setContextMenuVisible] = useState(false)
    const divMenuContext = useRef(null)

    const handleContextMenu = (event, record) => {
        const container = document.getElementById("container-table")
        const containerLeft = container.offsetLeft
        const containerTop = container.offsetTop
        event.preventDefault()
        setItem(record)
        setContextMenuPosition({ x: event.clientX - containerLeft, y: event.clientY - containerTop })
        setContextMenuVisible(true)
    }

    const handleDetect = (value) => {
        let _value = JSON.parse(value)
        handleSave(_value)
        setOpen(false)
        if (isBrowser) {
            var videoEl = document.getElementById('videoqr')
            var stream = videoEl.srcObject
            var tracks = stream.getTracks()
            tracks.forEach(function (track) {
                track.stop()
            })
            videoEl.srcObject = null
        }
    }

    const handleClose = () => {
        setOpen(false)
        if (isBrowser) {
            var videoEl = document.getElementById('videoqr')
            var stream = videoEl.srcObject
            var tracks = stream.getTracks()
            tracks.forEach(function (track) {
                track.stop()
            })
            videoEl.srcObject = null
        }
    }

    const handleSave = async (value) => {
        try {
            value.deviceId = value.id
            value.enabled = true
            value.id = undefined
            if (!value.password) {
                messageWarn(Translate.t("common.password.required"))
            } else {
                let { data } = await api.post(`/control/device/`, value)
                notification("success", "save", Translate.t("device.title"))
            }
        }
        catch (error) {
            messageError(error)
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        search({
            page: page.current - 1,
            rowPerPage: page.pageSize
        })
    }

    const handleRemove = async () => {
        try {
            if (item && item.id) {
                let { data } = await api.delete(`/control/device/${item.id}`)
                if (data > 0) {
                    notification('success', 'remove', Translate.t("device.title"))
                    setItem({})
                    handleTableChange(pagination)
                }

            } else {
                messageWarn(Translate.t("common.selectWarn"))
            }
        }
        catch (error) {
            messageError(error)
        }
    }

    const updateStatus = async () => {
        try {
            if (item && item.id) {
                let { data } = await api.put(`/control/device/update/status/${item.id}/${!item.enabled}`)
                if (data > 0) {
                    notification('success', 'update', Translate.t("device.title"))
                    handleTableChange(pagination)
                    setItem({})
                }
            } else {
                messageWarn(Translate.t("common.selectWarn"))
            }
        }
        catch (error) {
            messageError(error)
        }
    }

    const columns = [

        {
            index: 0,
            title: Translate.t("device.deviceId.title"),
            dataIndex: 'deviceId',
            key: 'deviceId',
            width: 600
        },
        {
            index: 1,
            title: Translate.t("common.location.title"),
            dataIndex: ["location", "name"],
            key: 'location.name',
            width: 200,
            render: (text, record) => <span>{(record.location && record.location.father ? record.location.father.name + " - " : "") + (record.location ? record.location.name : "")}</span>
        },
        {
            index: 2,
            title: Translate.t("common.status.title"),
            dataIndex: 'enabled',
            key: 'enabled',
            width: 200,
            render: (text, record) => text ? Translate.t("device.authorized") : Translate.t("device.blocked")
        },
        {
            index: 3,
            title: Translate.t("camera.model.title"),
            dataIndex: 'model',
            key: 'model',
            width: 200
        },
        {
            index: 4,
            title: Translate.t("device.brand.title"),
            dataIndex: 'brand',
            key: 'brand',
            width: 200
        },
        {
            index: 5,
            title: Translate.t("common.lastConnection"),
            dataIndex: 'lastCommunication',
            key: 'lastCommunication',
            width: 200,
            render: (text, record) => text ? datetime(text) : ""
        },
        {
            index: 6,
            title: Translate.t("common.creationDate"),
            dataIndex: 'creationDate',
            key: 'creationDate',
            width: 200,
            render: (text, record) => datetime(text)
        },
        {
            index: 7,
            title: Translate.t("common.id.title"),
            dataIndex: 'id',
            key: 'id',
            width: 250
        }

    ];

    const items = [
        {
            key: '1',
            item: (
                <Button disabled={!item} title={item.enabled ? Translate.t("device.block") : Translate.t("device.authorize")} icon={item.enabled ? <LockOutlined /> : <UnlockOutlined />}
                    onClick={() => {
                        setContextMenuVisible(false)
                        Modal.confirm({
                            title: Translate.t("message.confirm"),
                            content: item.enabled ? Translate.t("message.confirmContentBlock", { name: item.deviceId }) : Translate.t("message.confirmContentAuth", { name: item.deviceId }),
                            icon: <ExclamationCircleFilled />,
                            okText: Translate.t("action.yes"),
                            cancelText: Translate.t("action.no"),
                            onOk() {
                                return new Promise(async (resolve, reject) => resolve(await updateStatus(item))).catch(() => alert(Translate.t("error.operation")));
                            },
                            onCancel() { },
                        });
                    }}

                >
                    {item.enabled ? Translate.t("device.block") : Translate.t("device.authorize")}
                </Button>
            ),
        },
        {
            key: '2',
            item: (
                <Button key="remove" title={Translate.t("action.delete")} icon={<DeleteOutlined />} disabled={!item}
                    onClick={() => {
                        setContextMenuVisible(false)
                        Modal.confirm({
                            title: Translate.t("message.confirm"),
                            content: Translate.t("message.confirmContentDelete", { name: Translate.t("device.title") }),
                            icon: <ExclamationCircleFilled />,
                            okText: Translate.t("action.yes"),
                            cancelText: Translate.t("action.no"),
                            onOk() {
                                return new Promise(async (resolve, reject) => resolve(await handleRemove())).catch(() => alert(Translate.t("error.operation")));
                            },
                            onCancel() { },
                        });
                    }}
                >
                    {Translate.t("action.delete")}
                </Button>
            ),
        }]

    const handleHiddenColumns = (collIndex) => {
        if (columnsVisives.length > 0)
            return columnsVisives.find((i, index) => index === collIndex && i.visible)
        return true
    }

    const onChangeHiddenColumns = (index, checked) => {
        let _columns = (columnsVisives || []).map((i, _index) => ({ ...i, visible: _index === index ? checked : i.visible }))
        setValueStorage("columnsCamera", JSON.stringify(_columns))
        setColumnsVisives(_columns)
    }

    useEffect(() => {
        let value = getValueStorage("columnsDevice")
        if (value)
            setColumnsVisives(JSON.parse(value))
        else {
            let _columns = [
                { label: Translate.t("device.deviceId.title"), key: "0", visible: true },
                { label: Translate.t("common.location.title"), key: "1", visible: true },
                { label: Translate.t("common.status.title"), key: "2", visible: true },
                { label: Translate.t("camera.model.title"), key: "3", visible: true },
                { label: Translate.t("common.ip.title"), key: "4", visible: true },
                { label: Translate.t("device.brand.title"), key: "5", visible: true },
                { label: Translate.t("common.lastConnection"), key: "6", visible: true },
                { label: Translate.t("common.enabled"), key: "7", visible: true },
                { label: Translate.t("common.creationDate"), key: "8", visible: true },
                { label: Translate.t("common.id.title"), key: "9", visible: true },
            ]
            setValueStorage("columnsDevice", JSON.stringify(_columns))
            setColumnsVisives(_columns)
        }
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (divMenuContext.current && !divMenuContext.current.contains(event.target)) {
                setContextMenuVisible(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [divMenuContext])

    return (
        <>
            <Row id="container-table" style={{ marginTop: "20px" }}>
                <Col xs={24} className="card_default padding-list" >
                    <Row className="space-botton padding" >
                        <Col xs={12}>
                            <span className="text-header primary-text-color">{Translate.t("title.search_result")}</span>
                        </Col>
                        <Col xs={12} className="alng-rigth">
                            {columnsVisives.length > 0 && <Popover content={(columnsVisives || []).map((i, index) => <p value={i.label}>
                                <Checkbox checked={i.visible}
                                    onClick={(e) => {
                                        onChangeHiddenColumns(index, e.target.checked)
                                        e.stopPropagation()
                                    }}
                                >   <span className="secondary-text-color">{i.label}</span>
                                </Checkbox>
                            </p>)} placement="bottom" title={null} trigger="hover">
                                <span className="secondary-text-color"> {Translate.t("common.selectInfo")}</span>
                                <DownOutlined />
                            </Popover>}
                        </Col>
                    </Row >
                    <Table
                        scroll={{ y: 9999, x: 400 }}
                        columns={columns.filter((column) => handleHiddenColumns(column.index))}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        rowKey={(row) => row.id}
                        onChange={handleTableChange}
                        onRow={(record) => {
                            return {
                                onContextMenu: (event) => handleContextMenu(event, record)
                            }
                        }}
                    />
                    {contextMenuVisible && (
                        <ul ref={divMenuContext} className="popup" style={{ left: `${contextMenuPosition.x}px`, top: `${contextMenuPosition.y}px` }}>
                            {(items || []).map(i => <li className="secondary-text-color" key={i.key}>{i.item}</li>)}
                        </ul>
                    )}
                </Col>
            </Row >
            {open && <Modal
                destroyOnClose={true}
                title={<span className="text-header primary-text-color">{Translate.t("device.qrcode")}</span>}
                open={open}
                footer={null}
                onCancel={() => handleClose()}
            >
                <span className="text-sub secondary-text-color">{Translate.t("device.qrcodeinfo")}</span>
                <QrReader id="videoqr"
                    constraints={{
                        facingMode: "environment"
                    }}
                    onResult={(result, error) => {
                        if (!!result) {
                            handleDetect(result.text)
                        }
                        if (!!error) {
                            console.info(error);
                        }
                    }}
                    style={{ width: '100%' }}
                />
            </Modal>}
        </>
    )
}

export default List