import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tabs,
  TreeSelect,
} from "antd";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Translate } from "../../translate";
import { optionsDirections, optionsEnum } from "../../util/handleItens";
import { messageError, notification } from "../../util/messages";

const Register = ({
  initialValues = { enabled: true, deleted: false },
  open,
  locations,
  fetchLocations,
  types,
  setSelectedKeys,
  audiences,
  directions,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(
    initialValues.type ? initialValues.type : undefined
  );
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const handleItens = () => {
    let tab1 = {
      key: "1",
      label: (
        <span className="text-sub secondary-text-color">
          {" "}
          {Translate.t("location.generalData")}
        </span>
      ),
      children: (
        <Row gutter={[16, 0]}>
          <Form.Item name="id">
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="timestamp">
            <input type="hidden" />
          </Form.Item>
          <Col xs={24}>
            <Form.Item
              name="name"
              label={Translate.t("common.name.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.name.required"),
                },
              ]}
            >
              <Input
                placeholder={Translate.t("common.name.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="father" label={Translate.t("common.father.title")}>
              <TreeSelect
                showSearch
                style={{
                  width: "100%",
                }}
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
                placeholder={Translate.t("common.father.select")}
                disa
                allowClear
                treeDefaultExpandAll={true}
                treeData={locations}
                filterTreeNode={(search, item) => {
                  return (
                    item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="type"
              label={Translate.t("common.type.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.type.required"),
                },
              ]}
            >
              <Select
                allowClear
                onSelect={(i) => setType(i)}
                placeholder={Translate.t("common.type.select")}
                options={(optionsEnum(types) || []).map((i) => ({
                  ...i,
                  label: Translate.t(`location.${i.value}`),
                }))}
              />
            </Form.Item>
          </Col>
          {type === "bathroom" && (
            <Col xs={24}>
              <Form.Item
                name="audience"
                label={Translate.t("location.audience.title")}
                rules={[
                  {
                    required: true,
                    message: Translate.t("location.audience.required"),
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={Translate.t("location.audience.select")}
                  options={(optionsEnum(audiences) || []).map((i) => ({
                    ...i,
                    label: Translate.t(`location.${i.value}`),
                  }))}
                />
              </Form.Item>
            </Col>
          )}
          {type === "bathroom" && (
            <Col xs={12}>
              <Form.Item
                name="capacity"
                label={Translate.t("location.capacity.title")}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  placeholder={Translate.t("location.capacity.placeholder")}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={12}>
            <Form.Item
              name="enabled"
              valuePropName="checked"
              label={Translate.t("common.enabled")}
            >
              <Switch
                checkedChildren={Translate.t("action.yes")}
                unCheckedChildren={Translate.t("action.no")}
                defaultChecked
              />
            </Form.Item>
          </Col>

          {initialValues.id && (
            <Col xs={24} sm={12}>
              <Form.Item
                valuePropName="checked"
                label={Translate.t("common.id.title")}
              >
                <spam className="secondary-text-color">{initialValues.id}</spam>
              </Form.Item>
            </Col>
          )}
        </Row>
      ),
    };

    let tab2 = {
      key: "2",
      label: (
        <span className="text-sub secondary-text-color">
          {Translate.t("location.suggested")}
        </span>
      ),
      children: (
        <Row gutter={32}>
          <Col xs={24}>
            <span className="text-sub secondary-text-color">
              {Translate.t("location.suggested")}
            </span>
            <Form.List name="suggestions">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Row gutter={[16, 8]} style={{ marginTop: "10px" }}>
                        <Col xs={24} md={7}>
                          <Form.Item
                            {...field}
                            name={[index, "direction"]}
                            rules={[
                              {
                                required: true,
                                message: Translate.t(
                                  "common.direction.required"
                                ),
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              allowClear
                              placeholder={Translate.t(
                                "common.direction.select"
                              )}
                              options={optionsDirections(directions)}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={7}>
                          <Form.Item
                            {...field}
                            name={[index, "distance"]}
                            noStyle
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder={Translate.t(
                                "common.distance.placeholder"
                              )}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={7}>
                          <Form.Item
                            {...field}
                            name={[index, "local", "id"]}
                            rules={[
                              {
                                required: true,
                                message: Translate.t(
                                  "common.location.required"
                                ),
                              },
                            ]}
                            noStyle
                          >
                            <TreeSelect
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                              placeholder={Translate.t(
                                "common.location.select"
                              )}
                              allowClear
                              treeDefaultExpandAll
                              treeData={locations}
                              filterTreeNode={(search, item) => {
                                return (
                                  item.title
                                    .toLowerCase()
                                    .indexOf(search.toLowerCase()) >= 0
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={24}
                          md={3}
                          style={{ textAlign: "center", marginTop: "10px" }}
                        >
                          <MinusCircleOutlined
                            title={Translate.t("action.delete")}
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      title={Translate.t("common.add", {
                        name: Translate.t("location.suggested"),
                      })}
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      {Translate.t("common.add", {
                        name: Translate.t("location.suggested"),
                      })}
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      ),
    };
    return type !== "bathroom" ? [tab1] : [tab1, tab2];
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (values.id && values.father && values.id === values.father) {
        form.setFields([
          {
            name: "father",
            errors: [Translate.t("location.fatherMe")],
          },
        ]);
        return;
      }
      if (values.id && values.suggestions && values.suggestions.length > 0) {
        let me = values.suggestions.find((i) => i.local.id === values.id);
        if (me) {
          form.setFields([
            {
              name: "suggestions",
              errors: [Translate.t("location.suggestionMe")],
            },
          ]);
          return;
        }
      }
      if (values.father) values.father = { id: values.father };
      else values.father = undefined;
      let data = {};
      if (values.id) {
        data = await api.put(`/maintenance/location/${values.id}`, values);
        notification("success", "update", Translate.t("location.title"));
      } else {
        data = await api.post(`/maintenance/location/`, values);
        notification("success", "save", Translate.t("location.title"));
      }
      setSelectedKeys([data.data.id]);
      form.setFieldsValue({
        ...data.data,
        father: data.data.father ? data.data.father.id : undefined,
      });
      fetchLocations();
    } catch (error) {
      messageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      if (initialValues.type) setType(initialValues.type);
    }
  }, [initialValues]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues}
    >
      <Col xs={24}>
        <Tabs
          defaultActiveKey="1"
          style={{ maxWidth: "100%" }}
          items={handleItens()}
          onChange={onChange}
          activeKey={activeKey}
        />
        <Row>
          <Col xs={24}>
            <div style={{ float: "right", marginBottom: "20px" }}>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {Translate.t("action.ok")}
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
export default Register;
