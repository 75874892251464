import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  theme,
  TreeSelect,
} from "antd";
import React, { useState } from "react";
import api from "../../services/api";
import { Translate } from "../../translate";
import { messageError, notification } from "../../util/messages";

const Register = ({
  initialValues = {},
  open,
  setOpen,
  search,
  locations,
  hosts,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (values.id) {
        await api.put(`/control/camera/${values.id}`, values);
        notification("success", "update", Translate.t("camera.title"));
      } else {
        await api.post(`/control/camera/`, values);
        notification("success", "save", Translate.t("camera.title"));
      }
      setOpen(false);
      form.resetFields();
      search({});
    } catch (error) {
      messageError(error);
    } finally {
      setLoading(false);
    }
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const handelUrl = (value) => {
    return value.protocol + "://" + value.host + ":" + value.port + value.path;
  };


  return (
    <Modal
      title={
        <span>
          {initialValues.id
            ? Translate.t("action.edit", { name: Translate.t("camera.title") })
            : Translate.t("action.new", { name: Translate.t("camera.title") })}
        </span>
      }
      open={open}
      maskClosable={false}
      onCancel={() => onCancel()}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={16} className="default-padding">
          <Form.Item name="id">
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="deleted">
            <input type="hidden" />
          </Form.Item>
          <Col xs={24}>
            <Form.Item name="name" label={Translate.t("common.name.title")}>
              <Input
                placeholder={Translate.t("common.name.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="mac"
              label={Translate.t("common.mac.title")}
              rules={[
                { required: true, message: Translate.t("common.mac.required") },
              ]}
            >
              <Input
                placeholder={Translate.t("common.mac.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="ip"
              label={Translate.t("common.ip.title")}
              rules={[
                { required: true, message: Translate.t("common.ip.required") },
              ]}
            >
              <Input
                placeholder={Translate.t("common.ip.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="userName"
              label={Translate.t("common.username.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.username.required"),
                },
              ]}
            >
              <Input
                placeholder={Translate.t("common.username.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="password"
              label={Translate.t("common.password.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.password.required"),
                },
              ]}
            >
              <Input.Password
                style={{
                  borderRadius: token.borderRadius,
                  fontSize: token.fontSize,
                }}
                placeholder={Translate.t("common.password.placeholder")}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="serialNumber"
              label={Translate.t("common.serialNumber.title")}
            >
              <Input
                placeholder={Translate.t("common.serialNumber.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="model" label={Translate.t("camera.model.title")}>
              <Input
                placeholder={Translate.t("camera.model.placeholder")}
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name={["location", "id"]}
              label={Translate.t("common.location.title")}
              rules={[
                {
                  required: true,
                  message: Translate.t("common.location.required"),
                },
              ]}
            >
              <TreeSelect
                allowClear
                showSearch
                placeholder={Translate.t("common.location.select")}
                treeDefaultExpandAll
                treeData={locations}
                filterTreeNode={(search, item) => {
                  return (
                    item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name={["hosthttp", "id"]} label={Translate.t("hosthttp.title")}>
              <Select allowClear
                placeholder={Translate.t("hosthttp.select")}
                options={hosts.map((item) => ({
                  label:(item.name +" "+ handelUrl(item)),
                  value: item.id,
                }))}
              />
            </Form.Item>{" "}
          </Col>
          <Col xs={12}>
            <Form.Item
              name="enabled"
              valuePropName="checked"
              label={Translate.t("common.enabled")}
            >
              <Switch
                checkedChildren={Translate.t("action.yes")}
                unCheckedChildren={Translate.t("action.no")}
                defaultChecked
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <div className="btn-right">
              <Button
                key="back"
                onClick={() => onCancel()}
                style={{ marginRight: "10px" }}
              >
                {Translate.t("action.cancel")}
              </Button>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => onOk()}
              >
                {Translate.t("action.ok")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default Register;
