export function findNode(trees, id) {
  for (let tree of trees) {
    const result = findNodeInTree(tree, id);
    if (result) {
      return result;
    }
  }
  return null;
}

export function findNodeInTree(tree, id) {
  if (tree.key === id) {
    return tree;
  }
  if (tree.children) {
    for (let child of tree.children) {
      const result = findNodeInTree(child, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export function findPath(trees, id) {
  const node = findNode(trees, id);
  if (!node) return null;

  const path = [node.title];
  let current = node;

  while (current.father) {
    current = findNode(trees, current.father);
    if (current) {
      path.unshift(current.title);
    } else {
      break;
    }
  }

  return path.join(' > ');
}