import { Col, Row, Spin } from "antd";

import React, { useContext, useEffect, useState } from "react";
import { Translate } from "../../translate";

import dayjs from "dayjs";
import feedback from "../../assets/icons/actions/feedback.svg";
import percent from "../../assets/icons/actions/percent.svg";
import total_hours from "../../assets/icons/actions/total_hours.svg";
import users from "../../assets/icons/actions/users.svg";
import api from "../../services/api";
import { messageError } from "../../util/messages";
import { number } from "../../util/number";
import { AuthContext } from "../../context/auth";

const AvgWc = ({ start, end }) => {
  const { environment } = useContext(AuthContext);
  const [avgUsers, setAvgUsers] = useState();
  const [avgSurvey, setAvgSurvey] = useState();
  const [countHours, setCountHours] = useState();
  const [percentAvg, setPercentAvg] = useState();
  const [loading, setLoading] = useState(false);

  const fetchAvgUsers = async () => {
    try {
      let { data } = await api.get("/control/event/avg/date", {
        params: {
          types: "entry",
          start: start
            ? dayjs(start).toISOString()
            : dayjs().utc().hour(0).minute(0).second(0).toISOString(),
          end: end ? dayjs(end).toISOString() : dayjs().utc().toISOString(),
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const fetchAvgSurvey = async () => {
    try {
      let { data } = await api.get("/survey/survey/avg/date", {
        params: {
          start: start ? dayjs(start).toISOString() : undefined,
          end: end ? dayjs(end).toISOString() : undefined,
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const fetchCountHours = async () => {
    try {
      let { data } = await api.get("/maintenance/maintenance/count/time/date", {
        params: {
          enabled: true,
          start: start ? dayjs(start).toISOString() : undefined,
          end: end ? dayjs(end).toISOString() : undefined,
        },
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        let avgUsers = await fetchAvgUsers();
        let avgSurvey = await fetchAvgSurvey();
        let countHours = await fetchCountHours();
        if (avgUsers && avgSurvey)
          setPercentAvg(
            number((avgSurvey.value / avgUsers.value) * 100, 1) + " %"
          );
        else setPercentAvg("0 %");
        setAvgUsers(avgUsers);
        setAvgSurvey(avgSurvey);
        setCountHours(countHours.seconds / 3600);
      } catch (error) {
        messageError(error);
      } finally {
        setLoading(false);
      }
    }
    if (start && end) {
      loadData();
    }
  }, [start, end, environment]);

  return (
    <Row>
      <Col className="card_default" style={{ minHeight: "28.5em" }}>
        <div className="div-dash-itens">
          <span className="text-dashboard primary-text-color">
            {Translate.t("dashboard.avgwc")}
          </span>
          <Spin tip="Pesquisando " size="large" spinning={loading}>
            <Row xs={24} gutter={[32, 32]} style={{ marginTop: "1.5em" }}>
              <Col xs={4}>
                <img className="avg-img" src={users} alt="" />
              </Col>
              <Col xs={20}>
                <p className="text-dashboard primary-text-color">
                  Visitas por WC
                </p>
                <p className="secondary-text-color">
                  {avgUsers ? Math.round(avgUsers.value) : 0}
                </p>
              </Col>
              <Col xs={4}>
                <img className="avg-img" src={feedback} alt="" />
              </Col>
              <Col xs={20}>
                <p className="text-dashboard primary-text-color">Feedback</p>
                <p className="secondary-text-color">
                  {avgSurvey ? Math.round(avgSurvey.value) : 0}
                </p>
              </Col>
              <Col xs={4}>
                <img className="avg-img" src={percent} alt="" />
              </Col>
              <Col xs={20}>
                <p className="text-dashboard primary-text-color">
                  Percentagem média
                </p>
                <p className="secondary-text-color"> {percentAvg} </p>
              </Col>
              <Col xs={4}>
                <img className="avg-img" src={total_hours} alt="" />
              </Col>
              <Col xs={20}>
                <p className="text-dashboard primary-text-color">
                  Total de horas limpeza
                </p>
                <p className="secondary-text-color">
                  {number(countHours, 1)} Hrs
                </p>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
    </Row>
  );
};

export default AvgWc;
